.site-header-mobile {
    background-color: $site-header-mobile-bg;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $spacing-5 $spacing-5 0 $spacing-5;
    grid-template-columns: 1fr min-content min-content;
    grid-template-rows: min-content 1fr;
    column-gap: $spacing-5;
    transition: height 300ms;
    max-height: 100vh;
    overscroll-behavior: contain;
    border-bottom: 1px solid $site-header-border-color;
    &.is-open {
        position: fixed;
        height: 100vh;
    }

    &--dark {
        background-color: $nordea-blue-deep;

        &:not(.is-open) {
            border-bottom: 1px solid $nordea-blue-deep;
        }

        .site-header-mobile-top-actionmenu {
            min-height: 32px;
            flos-icon {
                color: $color-supplement-white-base;
            }
            .site-header-menu-item-link:not(.flos-button) {
                &:hover {
                    color: $nordea-blue-vivid;
                    flos-icon:not(.flos-link-arrow) {
                        color: $nordea-blue-vivid;
                    }
                }
            }
        }
    }
}

.site-header-mobile--with-secondary.is-open {
    height: calc(100vh - 60px);
}

// Fix to zendesk feedback widget on chat-bot icon in Mit Topdanmark
@media (max-width: $screen-sm-max) {
    #launcher {
        bottom: 60px !important;
        margin: $spacing-3 $spacing-3;
        z-index: 998 !important;
    }
    #topbot-widget div[class^='App_app'] {
        z-index: 999 !important;
        bottom: 40px !important;
    }
}

.site-header-mobile-top {
    display: flex;
    width: 100%;
    gap: $spacing-5;
    align-items: center;
    padding-bottom: $spacing-5;
    justify-content: space-between;

    flos-icon {
        display: inline-block;
        color: $site-header-link-color;
    }

    @media (min-width: $screen-xs) {
        gap: $spacing-7;
    }
}

.site-header-mobile-top-actionmenu {
    display: flex;
    gap: $spacing-5;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;

    .site-header-menu-item-link:not(.flos-button) {
        padding: 0;

        &:hover {
            color: $site-header-link-color--hover;
            flos-icon:not(.flos-link-arrow) {
                color: $site-header-link-color--hover;
            }
        }
    }

    .flos-button {
        width: auto;
        white-space: nowrap;
    }
}

.site-header-mobile-content {
    max-height: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $color-supplement-white-base;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    display: none;
    .is-open & {
        display: flex;
        max-height: 100vh;
        overscroll-behavior: contain;
        & ul {
            max-height: 100vh;
        }
    }
    & ul {
        max-height: 0;
        list-style: none;
        padding: 0;
        margin-bottom: 0;
    }
    .site-header-menu-item {
        position: static;
    }
}

.site-header-mobile-content-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-supplement-grey-light;
}

.site-header-mobile-content-bottom {
    padding: $spacing-9 0 0 0;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.site-header-mobile-close-btn {
    padding: $spacing-5;
}

.site-header-mobile-submenu {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0;
    padding: 0;
    background-color: $site-header-submenu-bg-color;
    list-style: none;
    transform: translate3d(100%, 0, 0);
    transition: transform 0.2s;
    z-index: 1;
    a {
        color: $site-header-mobile-link-color;

        & > flos-icon {
            display: inline-block;
            color: $site-header-mobile-link-color;
        }

        &:hover {
            color: $site-header-mobile-link-color--hover;

            flos-icon {
                color: $site-header-mobile-link-color--hover;
            }
        }
    }
}

.submenu-checkbox {
    display: none;
}

.submenu-checkbox:checked + .site-header-mobile-submenu {
    transform: translate3d(0, 0, 0);
}

.submenu-label {
    display: flex;
    justify-content: space-between;
    padding: $spacing-3;
    align-items: center;
    &.submenu-label-back {
        justify-content: flex-start;
        flos-icon {
            margin-right: $spacing-5;
        }
    }
}

.site-header-menu-icon {
    cursor: pointer;
    display: inline-block;
    float: right;
    padding: 16px 0 16px 0;
    position: relative;
    user-select: none;
    margin-bottom: 0;
    margin-top: -1px;
    @media (min-width: $screen-md) {
        display: none;
    }
}

.site-header-navicon {
    background: $site-header-mobile-navicon-color;
    display: block;
    height: 1px;
    position: relative;
    transition: background 0.2s ease-out, height 0.2s ease-out;
    width: 20px;

    &:before,
    &:after {
        background: $site-header-mobile-navicon-color;
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        transition: all 0.2s ease-out;
        width: 100%;
    }
    &:before {
        top: 10px;
    }
    &:after {
        top: -10px;
    }
}

.site-header-menu-btn {
    display: none;
    &:checked {
        & ~ .site-header-menu-icon {
            opacity: 1;
            .site-header-navicon {
                background: transparent;
                height: 1px;
                &:before {
                    transform: rotate(-45deg);
                }
                &:after {
                    transform: rotate(45deg);
                }
            }
            &:not(.steps) .site-header-navicon:before,
            &:not(.steps) .site-header-navicon:after {
                top: 0;
            }
        }
    }
}

.site-header-mobile-search-form {
    padding: $spacing-5;
    .flos-label {
        color: $color-secondary-4-medium;
        font-weight: 300;
    }
    .flos-field {
        margin-bottom: 0;
        .flos-input {
            background-color: $color-supplement-white-base;
            padding-left: $spacing-3;
        }
    }
}

.site-header-mobile-search-form-btn {
    appearance: none;
    border: none;
    background-color: transparent;
    flos-icon {
        color: $site-header-search-form-input-icon-stroke;
    }
}

.site-header-mobile-menu-item--back {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $spacing-9;
    border-bottom: 1px solid $color-supplement-grey-light;
}

.site-header-mobile-logout-link {
    color: $nordea-blue;
    font-weight: 700;
    padding: $spacing-5;
}
