.AttentionBanner {
    &--hidden {
        display: none;
    }

    position: relative;
    background: $color-primary-2-light;
    border: 1px solid $color-primary-2-base;
    border-right: 0;
    border-left: 0;
    box-sizing: border-box;
    padding: $spacing-4 0;
    width: 100%;

    @media (max-width: $screen-sm-max) {
        padding: $spacing-3 0;
    }

    .container {
        display: flex;
        align-items: center;
    }

    p {
        width: 100%;
        max-width: none;
        margin: 0;
        overflow: hidden;
    }

    flos-icon {
        color: $color-primary-1-dark;
        flex-shrink: 0;
        margin-right: $spacing-5;
    }

    &-close {
        float: right;
        margin-left: $spacing-5;
        margin-right: 0;
    }
}

// Themes
.AttentionBanner--warning {
    background: $color-supplement-warning-light;
    border-color: $color-supplement-warning-medium;
    color: $color-supplement-warning-dark;

    a {
        color: $color-supplement-warning-dark;
        font-weight: 600;

        &:hover {
            color: $color-supplement-warning-dark;
        }
    }

    flos-icon {
        color: $color-supplement-warning-dark;
    }
}
.AttentionBanner--success {
    background: $color-supplement-success-light;
    border-color: $color-supplement-success-medium;
    color: $color-supplement-success-dark;

    a {
        color: $color-supplement-success-dark;
        font-weight: 600;

        &:hover {
            color: $color-supplement-success-dark;
        }
    }

    flos-icon {
        color: $color-supplement-success-dark;
    }
}
.AttentionBanner--error,
.AttentionBanner--important {
    background: $color-supplement-error-light;
    border-color: $color-supplement-error-medium;
    color: $color-supplement-error-dark;

    a {
        color: $color-supplement-error-dark;
        font-weight: 600;

        &:hover {
            color: $color-supplement-error-dark;
        }
    }

    flos-icon {
        color: $color-supplement-error-dark;
    }
}
