$button-primary-bg: $color-secondary-2-base;
$button-primary-color: $color-supplement-white-base;
$button-primary-hover-bg: #0000ff;
$button-primary-active-bg: #0000ff;
$button-primary-active-color: $color-supplement-white-base;
$button-primary-padding: $spacing-4 $spacing-7;
$button-primary-padding-large: $spacing-5 $spacing-7;
$button-primary-padding-small: $spacing-2 $spacing-5;

$button-disabled-bg: #e0e1e2;
$button-disabled-color: $color-supplement-grey-medium;

$button-secondary-bg: $nordea-green;
$button-secondary-color: $color-primary-1-base;
$button-secondary-border: 1px solid $nordea-green;
$button-secondary-padding: calc(#{$spacing-4} - 1px) calc(#{$spacing-7} - 1px);
$button-secondary-padding-large: calc(#{$spacing-5} - 1px) calc(#{$spacing-7} - 1px);
$button-secondary-padding-small: calc(#{$spacing-2} - 1px) calc(#{$spacing-5} - 1px);
$button-secondary-hover-bg: $nordea-green-dark;
$button-secondary-hover-color: $color-primary-1-base;
$button-secondary-hover-border: 1px solid #2d8672;
$button-secondary-active-bg: $nordea-green-dark;
$button-secondary-active-color: $color-primary-1-base;
$button-secondary-disabled-bg: #e0e1e2;
$button-secondary-disabled-border: #e0e1e2;

$button-ghost-bg: $button-secondary-bg;
$button-ghost-color: $color-secondary-2-base;
$button-ghost-padding: $spacing-4 0;
$button-ghost-padding-small: $spacing-3 0;

@import './button';
