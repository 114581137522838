$transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$wizard-odd-size: 20px; // figma size

.flos-wizard {
    position: relative;
    &-steps {
        display: inline-block;
        margin: 0 auto;
        padding: $spacing-7 0;
        position: relative;
        width: 100%;
        transition-property: height;
        transition-timing-function: $transition-timing-function;
        transition-duration: inherit;

        &--vertical {
            align-items: stretch;
            display: flex;
            flex-direction: column;
            gap: $spacing-5;
            padding: 0;
        }
    }
    &-step {
        &--vertical {
            background-color: $color-supplement-grey-base;
            border-radius: $spacing-3;
            border: 1px solid transparent;
            display: flex;
            flex-direction: column;
            gap: $spacing-7;
            overflow: hidden;
            padding: $spacing-7;
            transition-duration: inherit;
            transition-property: background-color, border;
            transition-timing-function: $transition-timing-function;
        }

        &--active {
            background-color: $color-supplement-white-base;
            border: 1px solid $color-primary-2-base;
        }
    }
    &-title {
        align-items: center;
        display: flex;
        flex-wrap: nowrap;
        font-size: $wizard-odd-size;
        gap: $wizard-odd-size;
        line-height: $spacing-7;
        margin: 0;
        position: relative;
        transition-duration: inherit;
        transition-property: color;
        transition-timing-function: $transition-timing-function;

        &--small {
            font-size: $spacing-4;
            line-height: $spacing-5;
            text-align: center;
            color: $color-secondary-4-medium;
        }
        &--subtle {
            color: $color-supplement-grey-medium;
        }
    }
    &-icon {
        position: absolute;
        opacity: 1;
        left: 0;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        transition-property: transform, opacity;
        transition-duration: inherit;
        transition-timing-function: $transition-timing-function;

        &--hide {
            opacity: 0;
            transform: translate3d(-100%, -50%, 0);
        }
    }
    &-span {
        width: 100%;
        transform: translateX(0);
        transition-property: transform;
        transition-duration: inherit;
        transition-timing-function: $transition-timing-function;

        &--with-icon {
            transform: translateX($spacing-8);
        }
    }

    &-action {
        font-size: $size-font-base;
        z-index: 1;
    }
}
