.menu-secondary {
    z-index: 997;
    background-color: $secondary-menu-mobile-bg-color;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    flos-icon {
        color: $secondary-menu-mobile-link-color;
    }
    ul {
        list-style: none;
        display: flex;
        justify-content: space-around;
        width: 100%;
        padding: 0;
        margin: 0;
    }
    li {
        margin: 0;
        flex: 1;
    }
    .site-header-menu-item a:not(.flos-button) {
        padding: $spacing-2;
        font-size: $size-font-xxsmall;
        line-height: $size-font-xsmall;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $secondary-menu-mobile-link-color;
        gap: 0;
        font-weight: 400;

        flos-icon {
            margin-bottom: $spacing-2;
        }
        &:hover {
            color: $secondary-menu-mobile-link-color--hover;
            flos-icon {
                color: $secondary-menu-mobile-link-color--hover;
            }
        }
    }

    .site-header-menu-item {
        &.active > a:not(.flos-button) {
            color: $secondary-menu-mobile-link-color--hover;

            flos-icon {
                color: $secondary-menu-mobile-link-color--hover;
            }
        }
    }

    @media (min-width: $screen-md) {
        background: $secondary-menu-bg-color;
        position: sticky;
        top: 64px;
        ul {
            padding: $spacing-5;
            gap: $spacing-7;
            justify-content: center;
        }
        li {
            flex: 0;
            &.active > a:not(.flos-button) {
                color: $secondary-menu-link-color--hover;
            }
        }
        a:not(.flos-button) {
            color: $secondary-menu-link-color;
            font-size: $size-font-base;
            line-height: $size-lineheight-base;
            padding: 0;
            &:hover {
                color: $secondary-menu-link-color--hover;
            }
        }
        & flos-icon {
            display: none;
        }
    }
    @media (max-width: $screen-sm-max) {
        & + .site-footer {
            padding-bottom: 60px;
        }
    }
}
