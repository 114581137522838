.flos-accordion-panel {
    border-bottom: 1px solid $flos-accordion-panel-border;
    transition: all 0.2s ease-in;
    margin-bottom: $spacing-3;

    &:hover,
    &:focus-visible {
        background-color: $flos-accordion-panel-bgcolor--hover;
        border-color: $flos-accordion-panel-bgcolor--hover;
        border-radius: $border-radius-medium;
    }

    .flos-accordion-content-wrapper {
        display: none;
        overflow: hidden;
    }

    &.expanded {
        background-color: $color-supplement-white-base;
        border-color: $color-supplement-white-base;
        border-radius: $border-radius-medium;

        .flos-accordion-content-wrapper {
            display: block;
            animation: accordion-content-expand 0.2s ease-in;
        }

        .flos-accordion-panel-button {
            padding-bottom: 0;
        }

        .flos-accordion-panel-toggle-icon {
            transform: rotate(-180deg);
            transition: transform 0.2s;
        }
    }

    .flos-accordion-panel-toggle-icon {
        transform: rotate(0deg);
        transition: transform 0.2s;
        color: $flos-accordion-panel-toggle-icon-stroke;

        @media (min-width: $screen-md) {
            height: 32px !important;
            width: 32px !important;
        }
    }
}

.flos-accordion-panel-button {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: $spacing-5;
    align-items: center;
    text-align: left;
    background-color: transparent;
    border: none;
    padding: $spacing-5;
}

.flos-accordion-panel-content {
    display: flex;
    justify-content: space-between;
    gap: $spacing-5;
    align-items: center;
    width: 100%;
}

.flos-accordion-panel-title {
    h6 {
        font-weight: 700;
        color: $nordea-blue;
    }

    p {
        color: $flos-accordion-panel-subtitle-color;
    }
}

.flos-accordion:not(.flos-accordion--compact) {
    .flos-accordion-panel-title h6 {
        @media (min-width: $screen-md) {
            font-size: 18px;
            line-height: $size-lineheight-small;
        }
    }
}

// Variants
.flos-accordion.flos-accordion--compact {
    .flos-accordion-panel {
        border-bottom: 0;
        margin-bottom: 0;
        border-radius: 0;
    }

    .flos-accordion-panel-button {
        justify-content: start;
        flex-direction: row-reverse;
    }

    .flos-accordion-panel-toggle-icon {
        @media (min-width: $screen-md) {
            height: 16px !important;
            width: 16px !important;
        }
    }
}

// animation
@keyframes accordion-content-expand {
    0% {
        max-height: 75px;
    }
    10% {
        max-height: 125px;
    }
    20% {
        max-height: 175px;
    }
    30% {
        max-height: 225px;
    }
    40% {
        max-height: 275px;
    }
    50% {
        max-height: 300px;
    }
    60% {
        max-height: 375px;
    }
    70% {
        max-height: 425px;
    }
    100% {
        max-height: none;
    }
}
