$zindex-popover: 1060 !default;
$popover-arrow-width: 10px;
$popover-arrow-outer-width: 7px;
$popover-arrow-outer-color: transparent;
$popover-arrow-color: $nordea-white;

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: $zindex-popover;
    display: none;
    max-width: 300px;
    padding: 1px;
    // Our parent element can be arbitrary since popovers are by default inserted as a sibling of their target element.
    // So reset our font and text properties to avoid inheriting weird values.
    font-size: $font-size-base;
    background-color: $nordea-white;
    background-clip: padding-box;
    border-radius: $border-radius-large;

    // Offset the popover to account for the popover arrow
    &.top {
        margin-top: -$popover-arrow-width;
    }

    &.right {
        margin-left: $popover-arrow-width;
    }

    &.bottom {
        margin-top: $popover-arrow-width;
    }

    &.left {
        margin-left: -$popover-arrow-width;
    }

    // Arrows
    // .arrow is outer, .arrow:after is inner
    > .arrow {
        border-width: $popover-arrow-outer-width;

        &,
        &:after {
            position: absolute;
            display: block;
            width: 0;
            height: 0;
            border-color: transparent;
            border-style: solid;
        }

        &:after {
            content: '';
            border-width: $popover-arrow-width;
        }
    }

    &.top > .arrow {
        bottom: -$popover-arrow-outer-width;
        left: 50%;
        margin-left: -$popover-arrow-outer-width;
        border-top-color: $popover-arrow-outer-color;
        border-bottom-width: 0;

        &:after {
            bottom: 1px;
            margin-left: -$popover-arrow-width;
            content: ' ';
            border-top-color: $popover-arrow-color;
            border-bottom-width: 0;
        }
    }

    &.right > .arrow {
        top: 50%;
        left: -$popover-arrow-outer-width;
        margin-top: -$popover-arrow-outer-width;
        border-right-color: $popover-arrow-outer-color;
        border-left-width: 0;

        &:after {
            bottom: -$popover-arrow-width;
            left: 1px;
            content: ' ';
            border-right-color: $popover-arrow-color;
            border-left-width: 0;
        }
    }

    &.bottom > .arrow {
        top: -$popover-arrow-outer-width;
        left: 50%;
        margin-left: -$popover-arrow-outer-width;
        border-top-width: 0;
        border-bottom-color: $popover-arrow-outer-color;

        &:after {
            top: 1px;
            margin-left: -$popover-arrow-width;
            content: ' ';
            border-top-width: 0;
            border-bottom-color: $popover-arrow-color;
        }
    }

    &.left > .arrow {
        top: 50%;
        right: -$popover-arrow-outer-width;
        margin-top: -$popover-arrow-outer-width;
        border-right-width: 0;
        border-left-color: $popover-arrow-outer-color;

        &:after {
            right: 1px;
            bottom: -$popover-arrow-width;
            content: ' ';
            border-right-width: 0;
            border-left-color: $popover-arrow-color;
        }
    }
}

.popover-title {
    padding: 20px 14px 8px;
    margin: 0; // reset heading margin
    font-size: $font-size-base;
}

.popover-content {
    padding: 9px 14px;
}
