// nlp tokens
$nordea-blue: #0000a0;
$nordea-blue-deep: #00005e;
$nordea-blue-light: #dcedff;
$nordea-blue-medium: #99ccff;

$nordea-blue-vivid: #0000ff;

$nordea-green: #40bfa3;
$nordea-green-dark: #0d8268;

$nordea-red: #fc6161;
$nordea-red-dark: #e70404;

$nordea-yellow: #ffe183;
$nordea-yellow-dark: #ffcf3d;

$nordea-black: #000;

$nordea-white: #ffffff;

$nordea-grey-cool: #f1f2f4;
$nordea-grey-light: #e9e9e9;
$nordea-grey-medium: #c9c7c7;
$nordea-grey: #8b8a8d;
$nordea-grey-dark: #5a575c;

// tokens
$color-supplement-white-light: $nordea-white;
$color-supplement-white-medium: $nordea-white;
$color-supplement-white-dark: $nordea-white;
$color-supplement-white-base: $nordea-white;
$color-supplement-black-light: $nordea-black;
$color-supplement-black-medium: $nordea-black;
$color-supplement-black-dark: $nordea-black;
$color-supplement-black-base: $nordea-black;
$color-supplement-grey-light: $nordea-grey-cool;
$color-supplement-grey-medium: $nordea-grey-medium;
$color-supplement-grey-dark: $nordea-grey-dark;
$color-supplement-grey-base: $nordea-grey;
$color-supplement-success-light: $nordea-green;
$color-supplement-success-medium: $nordea-green;
$color-supplement-success-dark: $nordea-green-dark;
$color-supplement-success-base: $nordea-green;
$color-supplement-warning-light: $nordea-yellow;
$color-supplement-warning-medium: $nordea-yellow;
$color-supplement-warning-dark: $nordea-yellow-dark;
$color-supplement-warning-base: $nordea-yellow;
$color-supplement-error-light: $nordea-red;
$color-supplement-error-medium: $nordea-red;
$color-supplement-error-dark: $nordea-red-dark;
$color-supplement-error-base: $nordea-red;
$color-secondary-4-light: $nordea-blue-light;
$color-secondary-4-medium: $color-supplement-grey-base;
$color-secondary-4-dark: $color-supplement-grey-dark;
$color-secondary-4-base: $nordea-blue-light;
$color-secondary-3-light: $color-supplement-grey-medium;
$color-secondary-3-medium: $color-supplement-grey-base;
$color-secondary-3-dark: $color-supplement-grey-dark;
$color-secondary-3-base: $nordea-green;
$color-secondary-2-light: $nordea-blue-light;
$color-secondary-2-medium: $nordea-blue-medium;
$color-secondary-2-dark: $nordea-blue-deep;
$color-secondary-2-base: $nordea-blue;
$color-secondary-1-light: $color-supplement-grey-light;
$color-secondary-1-medium: $color-supplement-grey-medium;
$color-secondary-1-dark: $color-supplement-grey-dark;
$color-secondary-1-base: $color-supplement-grey-base;
$color-primary-2-light: $nordea-blue-light;
$color-primary-2-medium: $nordea-blue-medium;
$color-primary-2-dark: $nordea-blue-deep;
$color-primary-2-base: $nordea-blue-medium;
$color-primary-1-light: $nordea-blue-deep;
$color-primary-1-medium: $nordea-blue-deep;
$color-primary-1-dark: $nordea-black;
$color-primary-1-base: $nordea-blue-deep;
$spacing-13: 128px;
$spacing-12: 80px;
$spacing-11: 64px;
$spacing-10: 56px;
$spacing-9: 48px;
$spacing-8: 40px;
$spacing-7: 32px;
$spacing-6: 24px;
$spacing-5: 16px;
$spacing-4: 12px;
$spacing-3: 8px;
$spacing-2: 4px;
$size-lineheight-xgiant: 112px;
$size-lineheight-giant: 44px;
$size-lineheight-xxlarge: 36px;
$size-lineheight-xlarge: 40px;
$size-lineheight-large: 32px;
$size-lineheight-medium: 28px;
$size-lineheight-small: 24px;
$size-lineheight-xsmall: 16px;
$size-font-xgiant: 8rem; // Our largest font size. Used for things like title tags in Hero's or PageHeader's desktop
$size-font-giant: 5.6rem; // Our giant font size. Used for things like title tags in Hero's or PageHeader's mobile
$size-font-xxlarge: 4.8rem; // the extra extra large size of the font
$size-font-xlarge: 4rem; // the extra large size of the font
$size-font-large: 3.2rem; // the large size of the font
$size-font-medium: 2.2rem; // the medium size of the font
$size-font-small: 2rem; // the third smallest size of the font
$size-font-xsmall: 1.6rem; // the second smallest size of the font
$size-font-xxsmall: 1.2rem; // the smallest size of the font
$innershadow-small: 2px 2px 2px rgba(0, 0, 0, 0.8);
$shadow-large: 4px 13px 26px rgba(0, 0, 0, 0.15);
$shadow-medium: 0 1px 12px rgba(0, 0, 0, 0.15);
$shadow-small: 0 1px 4px rgba(0, 0, 0, 0.15);
$border-radius-large: 45px;
$border-radius-medium: 8px;
$border-radius-small: 4px;
$size-lineheight-base: $size-lineheight-small;
$size-font-base: $size-font-xsmall; // the base size of the font
