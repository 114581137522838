.Box {
    &--footer-cta {
        $height-footer-cta: 53px;
        $height-footer-cta-sm-min: 53px;
        display: block;
        position: absolute;
        line-height: 53px;
        border-top: 1px solid $color-primary-1-base;
        transition: background-color 0.4s;
        font-weight: 400;
        bottom: 0;
        width: 100%;
        margin-left: -$spacing-5;
        margin-right: -$spacing-5;

        @media (min-width: $screen-sm) {
            margin-left: -$spacing-5;
            margin-right: -$spacing-5;
        }

        ~ *:last-child {
            margin-bottom: $height-footer-cta;

            @media (min-width: $screen-sm) {
                margin-bottom: $height-footer-cta-sm-min;
            }
        }

        &.is-active,
        &:hover,
        &:focus,
        &:active {
            background-color: $color-secondary-4-base;
        }

        flos-icon {
            color: $color-primary-1-base;
        }
    }

    &__label {
        position: absolute;
        top: 0;
        right: 0;
        padding: 4px 16px 2px 16px;
        font-size: $size-font-base;
        /* .label-warning */
        background-color: #f0ad4e;
        /* .label */
        line-height: 1;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        font-weight: 700;
    }

    &--with-label {
        padding-top: 25px !important;
        padding-bottom: 25px !important;
    }
}

.Box--agreement {
    width: 100%;
    position: relative;
    padding: $spacing-5;
    margin: 15px 0;
    color: $color-primary-1-base;
    border-radius: $border-radius-small;
    background: $color-supplement-white-base;
    min-height: 0;
    transition: 0.4s;

    &:hover {
        .Box-header {
            &::before,
            &::after {
                margin-left: 5px;
            }
        }
    }

    @media (min-width: $screen-sm) {
        padding: $spacing-5;
    }

    span {
        display: block !important;
        margin-left: 35px;
        margin-top: 3px;
        font-weight: 300;
    }

    &.checkbox {
        &::before {
            display: block;
            min-width: 20px;
            float: left;
        }
    }
}

.Box--center {
    text-align: center;
}

.Box--items-center {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

@mixin insurance-icon-svg() {
    border: none;
    border-radius: 0;
    width: 60px;
    height: 60px;

    &::after {
        background-image: none;
    }
}

.Box-icon,
.Coverage-icon {
    display: inline-block;
    vertical-align: middle;
    width: 40px;
    height: 40px;
    position: relative;

    &::after {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        content: '';
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
    }

    &--success {
        stroke: $color-supplement-success-base;
    }

    &--warning {
        background-color: rgba($color-secondary-1-base, 0.65);
        border-color: $color-secondary-1-base;

        &::after {
            @extend .ico-cross-warning;
        }
    }

    &--error {
        background-color: rgba($color-supplement-error-base, 0.45);
        border-color: $color-supplement-error-base;
        @include box-shadow(2px 2px 0 rgba($color-supplement-error-base, 0.25));

        &::after {
            @extend .ico-cross-stroked;
            width: 18px;
            height: 18px;
        }
    }

    &--child {
        background-color: inherit;
        border: none;

        &::after {
            @extend .ico-child;
            width: 40px;
            height: 40px;
        }
    }
}

.Box-image-button {
    /*margin-bottom: 4vh;*/
    margin-right: 4vh;

    @media (max-width: $screen-xs-max) {
        margin-right: 2vh;
    }

    &:last-child:after {
        clear: both;
        content: ' ';
        display: table;
    }

    input {
        visibility: hidden;
        position: absolute;

        & + label {
            cursor: pointer;
            text-align: center;
            background-color: #fff;
            padding: 12px 32px;
            position: relative;
            color: $color-supplement-grey-dark;
            border-radius: $border-radius-medium;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 5px 16px 0 rgba(0, 0, 0, 0.1);
            transition: background 0.4s, color 0.4s;
            width: 100%;
            display: inline-block;

            span {
                font-size: $size-font-small;
                display: block;
                color: $color-supplement-grey-dark;
            }

            svg {
                width: 100%;
                height: auto;
                cursor: pointer;
                fill: $color-primary-2-base;
                stroke: $color-primary-2-base;
            }

            img {
                pointer-events: none;
                /* IE workaround */
                width: 100%;
                height: auto;
                cursor: pointer;
                padding-bottom: 6px;
                fill: $color-primary-2-base;
                stroke: $color-primary-2-base;
            }
        }

        &:checked + label {
            background-color: $color-primary-2-base;

            svg {
                fill: #fff;
                stroke: #fff;
            }

            span {
                color: #fff;
            }
        }
    }
}

.Box-buttons {
    margin-top: 2vh;
}

.Box-button-square-2 {
    float: left;
    height: 0;
    width: 50%;
    padding-bottom: 50%;
}

.Details-Wrapper {
    margin-left: 45px;
    margin-top: -15px;

    @media (max-width: $screen-sm) {
        margin-left: 20px;
        margin-top: 0;
    }

    .Insurance-Desc {
        @media (max-width: $screen-xs) {
            padding-right: 15px;
        }
    }
}

.Box-group {
    margin: 15px 0 !important;

    .Box:first-child {
        border-bottom: 1px solid $color-supplement-grey-light;
    }

    .Box {
        box-shadow: none;
        margin: 0;
    }

    .Box:nth-child(even) {
        background-color: $color-supplement-grey-light;
    }
}

.loading-dots {
    display: none;
    font-size: $size-font-xsmall;
    margin-left: 38px; /* Added by PUF - 2017-01-10*/
    text-align: left; /* Added by PUF 2017-03-10*/
    width: 0;
    @media only screen and (min-width: 320px) and (max-width: 420px) {
        margin-left: 19px;
    }
    &:after {
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        animation: ellipsis steps(4, end) 1600ms infinite;
        content: '\2026'; /* ascii code for the ellipsis character */
        width: 0;
    }
}

@keyframes ellipsis {
    to {
        width: 1em;
    }
}

.Box--disabled {
    color: #c6c6c6;
    cursor: not-allowed;
}

@media (min-width: 768px) {
    .errorMessages {
        padding: 40px 30px;
    }
}

.Box.Box--ksp-ext {
    padding: 0;
    border-radius: $border-radius-small;
}

.Box--ksp-ext {
    .Box--ksp__container {
        padding: 20px 30px;

        @media (max-width: 772px) {
            padding: 20px 15px;
        }
    }

    .Box--ksp__tag {
        height: 55px;

        span {
            background-color: $color-supplement-success-base;
            font-size: $size-font-xsmall;
            width: 220px;
            font-weight: bold;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 5px 10px 0 rgba(0, 0, 0, 0.2);

            &:before,
            &:after {
                width: 220px;
                background-color: $color-supplement-success-base;
                z-index: 0;

                @media (max-width: 772px) {
                    height: 4px;
                }
            }
        }

        @media (max-width: 772px) {
            height: 30px;
            left: calc(50% - 110px);
        }
    }

    .Box--ksp__header {
        width: calc(100% - 220px);

        @media (max-width: 772px) {
            text-align: center;
            width: 100%;
            padding-top: 25px;
        }
    }

    .Box--ksp__content {
        padding-top: 25px;
    }

    .Box-header {
        font-size: $size-font-large;
        font-weight: 700;

        @media (max-width: 772px) {
            display: block;
            font-size: $size-font-base;
        }
    }

    &_infoText {
        text-align: center;
        font-size: $size-font-small;
        font-weight: 300;
        padding-bottom: 10px;
    }

    &_info-icons-wrapper {
        text-align: center;
        font-size: $size-font-small;
        font-weight: 300;
        margin-bottom: 30px;

        .review-damage,
        .review-share {
            display: inline-block;
            padding: 0 10px;

            a {
                cursor: default;
                color: $color-supplement-grey-dark;
            }
        }

        .ico-review-damage,
        .ico-review-share,
        .ico-review-svg,
        .ico-share-svg {
            width: 26px;
            height: 26px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 5px;

            a {
                cursor: pointer;
            }
        }
    }

    &_normal-price,
    &_agent-price,
    &_your-price {
        text-align: center;
        padding-bottom: 2px;
    }

    &_normal-price {
        color: $color-supplement-grey-light;
        font-size: $size-font-xxsmall;
        font-weight: 300;

        span {
            text-decoration: line-through;
        }
    }

    &_agent-price {
        font-size: $size-font-xxsmall;
        font-weight: 300;
    }

    &_your-price {
        font-size: $size-font-xsmall;
        font-weight: 600;

        @media (max-width: 772px) {
            padding-bottom: 15px;
        }
    }

    &_btn {
        width: 100%;
        background-color: $color-supplement-success-base;
        color: $color-supplement-white-base;
        height: 55px;
        box-sizing: border-box;

        .ico-thumsup-ext {
            fill: $color-supplement-white-base;
            color: $color-supplement-white-base;
            display: inline-block;
            vertical-align: middle;
            width: 24px;
            height: 24px;
        }

        span.spanFadeIn {
            animation: fadeInFromNone 0.25s ease-in-out;
        }
    }

    .tds-Coverage--inline .tds-Coverage-header {
        border-bottom: none;
        padding: 5px 0 5px 0;

        &:before {
            right: 10px;
        }

        &:after {
            right: 14px;
        }
    }

    .tds-Coverage--inline .tds-Coverage-icon ~ .tds-Coverage-titlegroup {
        padding-left: 0;
        margin-left: 40px;
        width: calc(100% - 40px);
        display: inline-block;
        border-bottom: 1px solid $color-supplement-grey-light;
        padding-bottom: 5px;
    }

    .tds-Coverage--inline .tds-Coverage-icon {
        margin-top: -2px;
    }

    .tds-Coverage--inline .tds-Coverage-in--sub,
    .tds-Coverage--inline .tds-Coverage-ex--sub {
        top: 14%;
        transform: translateY(-14%);
    }

    .tds-Coverage--inline .tds-Coverage-content {
        padding: 20px 0 20px 40px;
        font-size: $size-font-xsmall;

        @media (max-width: 772px) {
            font-size: $size-font-xsmall;
            padding: 20px 0 20px 0;
        }

        .Section-custom-content {
            padding: 10px;
            font-size: $size-font-xsmall;

            ul {
                margin: 0 0 0 5px;

                li {
                    padding: 5px 0;
                }
            }
        }
    }

    .tds-Coverage--inline .tds-Coverage-listitem {
        margin-left: 5px;

        @media (max-width: 772px) {
            font-size: $size-font-xsmall;
        }
    }

    .btn {
        border-radius: 0 0 $border-radius-small $border-radius-small;

        @media (max-width: 992px) {
            display: none;
        }
    }

    .tds-cover-head-desc {
        max-width: none;
        padding: 0 30px 5px 0;

        span {
            display: block;
            padding-bottom: 10px;

            &:nth-last-child(1) {
                padding-bottom: 0;
            }
        }
    }
}

@keyframes fadeInFromNone {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

.Box.Box--deductible {
    border-radius: $border-radius-small;
    padding: 0;
    border: 2px solid transparent;
}

.Box.Box--deductible-selected {
    border: 2px solid $color-primary-2-base;
    box-shadow: none;

    &:hover {
        background-color: $color-supplement-white-base;
        cursor: default;
    }
}

.tds-RadioGroup--complex .form-group .input-list .Box--deductible .radio-group-item {
    padding: 0;
}

.tds-RadioGroup--complex .form-group .input-list .Box--deductible-selected .radio-group-item label.radio {
    cursor: default;
}

.deductible-form-group .form-group {
    max-width: 100% !important;
}

.Box--deductible {
    transition: all 0.12s;

    .Box--ksp__tag {
        height: 30px;

        span {
            background-color: $color-primary-2-base;
            font-size: $size-font-xsmall;
            font-weight: bold;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 5px 10px 0 rgba(0, 0, 0, 0.2);
        }
    }

    .radio-group-item {
        label.radio {
            margin: 0 !important;
            padding: 20px;
            width: 100%;
            box-sizing: border-box;

            &:before {
                margin-left: 0;
                margin-right: 15px;
            }

            &:after {
                top: 29px;
                left: 25px;
            }

            span {
                padding-left: 30px;
                display: block !important;
            }

            span.deductible-title {
                font-size: $size-font-small;
                font-weight: 400;
                padding-bottom: 10px;
                padding-left: 0;
                vertical-align: top;
                display: inline-block !important;
            }

            span.deductible-desc {
                font-size: $size-font-xsmall;
                padding-bottom: 15px;
                font-weight: 300;
            }

            span.deductible-price {
                font-size: $size-font-small;
                font-weight: 300;
            }

            span.deductible-remarks {
                font-size: $size-font-xxsmall;
                font-weight: 300;
            }
        }

        label.radio-most-viewed {
            span.deductible-title {
                width: calc(100% - 200px);
            }
        }
    }

    &:hover {
        background-color: $color-supplement-grey-light;
    }
}

.Box--deductible-tablet-view {
    .input-list {
        display: flex;
    }

    .Box--deductible {
        max-width: 360px;
        margin-right: 10px;
        flex: 1;
        flex-direction: column;
    }
}

.Box--deductible-mobile-view {
    .Box--deductible {
        .Box--ksp__tag {
            height: 30px;
            left: calc(50% - 55px);

            span {
                width: 120px;
                font-size: $size-font-xsmall;
            }
        }

        .radio-group-item {
            label.radio {
                padding: 30px 20px 20px;

                &:after {
                    top: 39px;
                }

                span.deductible-title {
                    font-size: $size-font-xsmall;
                    width: calc(100% - 30px);
                    vertical-align: text-top;
                    margin-top: -2px;
                }

                span.deductible-desc {
                    font-size: $size-font-xsmall;
                }

                span.deductible-price {
                    font-size: $size-font-xsmall;
                }

                span.deductible-remarks {
                    font-size: $size-font-xxsmall;
                    font-weight: 300;
                }
            }
        }
    }
}

.swiper-container {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    /* Fix of Webkit flickering */
    z-index: 1;
}

.swiper-container-no-flexbox {
    .swiper-slide {
        float: left;
    }
}

.swiper-container-vertical > .swiper-wrapper {
    flex-direction: column;
}

.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
    align-items: flex-end;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
    transform: translate3d(0, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
    flex-wrap: wrap;
}

.swiper-container-free-mode > .swiper-wrapper {
    transition-timing-function: ease-out;
    margin: 0 auto;
}

.swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;

    @media (max-width: 460px) {
        width: auto;
    }
}

.swiper-slide-invisible-blank {
    visibility: hidden;
}

/* Auto Height */
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
    height: auto;
}

.swiper-container-autoheight .swiper-wrapper {
    align-items: flex-start;
    transition-property: transform, height;
}

/* 3D Effects */
.swiper-container-3d {
    perspective: 1200px;

    .swiper-wrapper,
    .swiper-slide,
    .swiper-slide-shadow-left,
    .swiper-slide-shadow-right,
    .swiper-slide-shadow-top,
    .swiper-slide-shadow-bottom,
    .swiper-cube-shadow {
        transform-style: preserve-3d;
    }

    .swiper-slide-shadow-left,
    .swiper-slide-shadow-right,
    .swiper-slide-shadow-top,
    .swiper-slide-shadow-bottom {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        z-index: 10;
    }

    .swiper-slide-shadow-left {
        background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }

    .swiper-slide-shadow-right {
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }

    .swiper-slide-shadow-top {
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }

    .swiper-slide-shadow-bottom {
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

/* IE10 Windows Phone 8 Fixes */
.swiper-container-wp8-horizontal,
.swiper-container-wp8-horizontal > .swiper-wrapper {
    touch-action: pan-y;
}

.swiper-container-wp8-vertical,
.swiper-container-wp8-vertical > .swiper-wrapper {
    touch-action: pan-x;
}

.Box--article {
    .Box-body {
        padding: 0 30px 30px 30px;
        margin: 0;
    }
    .Related-products &:hover {
        background-color: $color-secondary-4-base;
    }
}
