@use 'sass:math';

// These styles can be deleted when all apps are using flos-radio-field and-or flos-radio
// There is some styling for radio in the checkbox_deprecated.scss in the checkbox component folder

$circle: 24px;
$dotSize: math.div($circle, 2.7);

.radio {
    .dot {
        width: $circle;
        height: $circle;
        padding: 3px;
        border-radius: 50%;
        flex-shrink: 0;
    }

    &.has-error {
        & > .dot {
            border-color: $color-secondary-2-base;
        }
    }
    &.has-success {
        & > .dot {
            border-color: $color-primary-2-base;
        }
    }

    &.is-disabled {
        & > .dot {
            border-color: $color-supplement-grey-dark;
        }
    }
}

fieldset.is-required legend::after {
    color: $color-primary-1-base;
    font-weight: 400;
}

label.radio.is-required::after,
label.checkbox.is-required::after {
    content: none;
}

input[type='radio']:checked + .radio {
    & > .dot {
        background-image: radial-gradient(circle at center, $color-secondary-2-base $dotSize, #00000000 5.2px);
    }
    &.has-error {
        & > .dot {
            background-image: radial-gradient(circle at center, $color-secondary-2-base $dotSize, #00000000 5.2px) !important;
        }
    }
    &.has-success {
        & > .dot {
            background-image: radial-gradient(circle at center, $color-secondary-2-base $dotSize, #00000000 5.2px) !important;
        }
    }

    &.is-disabled {
        & > .dot {
            background-image: radial-gradient(circle at center, $color-supplement-grey-dark $dotSize, #00000000 5.2px) !important;
        }
    }
}

// Claim form temp fix below

.tds-RadioGroup {
    input[type='radio']:checked + .radio::after {
        background-color: $color-secondary-2-base;
    }

    .radio {
        &::before {
            display: inline-block;
            width: $spacing-5;
            height: $spacing-5;
            position: relative;
            vertical-align: 0;
            content: '';
            border: 1px solid $color-secondary-2-base;
            background-color: transparent;
            transition: border 0.2s;
            top: 1px;
            border-radius: 50%;
            margin-left: -25px; /* Added 2018-05-14 */
            margin-right: $spacing-5;
        }
        &::after {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            left: 20px;
            top: 22px;
            border-radius: 50%;
            @media (max-width: $screen-sm-max) {
                top: 22px;
            }
        }
        &:hover::before {
            border-color: $color-primary-1-base;
        }

        &.has-error {
            &::before {
                border-color: $color-supplement-error-base !important;
            }
        }
        &.has-success {
            &::before {
                border-color: $color-supplement-success-base !important;
            }
        }
    }
    $tdsRadioGroup: &;
    &.hide-asterisk {
        #{$tdsRadioGroup}-title::after {
            content: none;
        }
    }
    &--complex {
        &.has-error {
            #{$tdsRadioGroup} {
                &-title {
                    color: $color-supplement-error-base !important;
                }
            }
        }
        &.has-success {
            #{$tdsRadioGroup} {
                &-title {
                    color: $color-supplement-success-base !important;
                }
            }
        }
        .form-group {
            .input-list {
                .radio-group-item {
                    padding-right: 15px;
                    display: inline-block;
                    @include clearfix;
                    label.radio {
                        &::before {
                            margin-left: 0;
                            margin-right: 10px;
                        }
                        &::after {
                            margin-left: 0;
                        }
                        cursor: pointer;
                        display: table;

                        &.required {
                            span {
                                display: table-cell;
                                &::after {
                                    content: '\00a0*';
                                    color: $color-supplement-error-base;
                                    font-size: $size-font-xsmall;
                                    line-height: 1;
                                }
                            }
                        }
                        &.disabled {
                            cursor: inherit;
                        }
                        span {
                            display: table-cell;
                        }
                        flos-icon {
                            padding-left: 3px;
                            margin-bottom: 5px;
                        }
                        i {
                            display: table-cell;
                            vertical-align: top;
                            .tds-Icon {
                                fill: #414141;
                                stroke: #414141;
                                color: #414141;
                                width: 30px;
                                height: 25px;
                                margin-top: 2px;
                            }
                        }
                    }
                }
                &--stacked,
                &--vertical {
                    .radio-group-item {
                        display: block;
                    }
                }
            }
        }
    }
}
