@use 'sass:math';

.container {
    @include container-fixed;

    @media (min-width: $screen-lg) {
        width: $container-lg;
    }

    &-no-padding {
        padding-left: 0;
        padding-right: 0;
    }

    &--edge-to-edge-touch-device {
        @media (any-hover: none) {
            padding-right: 0;
            padding-left: 0;
        }
    }
}

// ! .col-(xs|sm|md|lg)-(offset-)?#{$i}
// ! might be deprecated along with bootstrap please stop uasing it.
.row {
    @include make-row;
}
@include make-grid-columns;
@include make-grid(xs);
@media (min-width: $screen-sm) {
    @include make-grid(sm);
}
@media (min-width: $screen-md) {
    @include make-grid(md);
}
@media (min-width: $screen-lg) {
    @include make-grid(lg);
}
// !

@mixin item-width($width: 1) {
    -ms-grid-column-end: span $width;
    grid-column-end: span $width;
}
@mixin item-position($position: 1) {
    -ms-grid-column-start: $position;
    grid-column-start: $position;
}

@mixin item-row-position($position: 1) {
    -ms-grid-row-start: $position;
    grid-row-start: $position;
}

.flos-grid {
    display: grid;
    gap: $spacing-5;
    grid-template-columns: repeat(12, 1fr);
    @media (max-width: $screen-sm-max) {
        grid-template-columns: 1fr;
        gap: $spacing-7;
        & > div {
            grid-column: 1 / span 1;
        }
    }
}
.flos-grid--span {
    @media (min-width: $screen-md) {
        @for $i from 1 through 12 {
            &-#{$i} {
                @include item-width($width: #{$i});
            }
        }
    }
}
.flos-grid--col-start {
    @media (min-width: $screen-md) {
        @for $i from 1 through 12 {
            &-#{$i} {
                @include item-position($position: #{$i});
            }
        }
    }
}

.flos-grid--row-start-mobile {
    @media (max-width: $screen-sm-max) {
        @for $i from 1 through 12 {
            &-#{$i} {
                @include item-row-position($position: #{$i});
            }
        }
    }
}

.flos-grid--gap {
    @for $i from 2 through 12 {
        &-#{$i} {
            gap: map-get(
                (
                    2: $spacing-2,
                    3: $spacing-3,
                    4: $spacing-4,
                    5: $spacing-5,
                    6: $spacing-6,
                    7: $spacing-7,
                    8: $spacing-8,
                    9: $spacing-9,
                    10: $spacing-10,
                    11: $spacing-11,
                    12: $spacing-12,
                ),
                $i
            );
        }
    }
    &-none {
        gap: 0;
    }
    &-row-none {
        row-gap: 0;
    }
}
.flos-grid--two-col {
    & > div:nth-child(1) {
        grid-column: 1 / span 7;
    }
    & > div:nth-child(2) {
        grid-column: 9 / span 4;
    }
    @media (max-width: $screen-sm-max) {
        & > div:nth-child(1) {
            grid-column: 1 / span 12;
        }
        & > div:nth-child(2) {
            grid-column: 1 / span 12;
        }
    }
}
.flos-grid--narrow {
    & > div:nth-child(1) {
        grid-column: 3 / span 8;
    }
    @media (max-width: $screen-sm-max) {
        & > div:nth-child(1) {
            grid-column: 1 / span 12;
        }
    }
}

.flos-grid-area-title {
    grid-area: title;
    align-self: center;
}
.flos-grid-area-right {
    grid-area: right;
}
.flos-grid-area-content {
    grid-area: content;
}

.flos-grid--right-and-under {
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    grid-template-areas:
        'title flex'
        'content content';

    @media (max-width: $screen-sm-max) {
        gap: $spacing-5;
        grid-template-columns: auto;
        grid-template-areas:
            'title'
            'content'
            'right';
    }
}

.row--narrow {
    $narrow-gutter: math.div($grid-gutter-width, 4);

    margin-left: -$narrow-gutter;
    margin-right: -$narrow-gutter;

    [class*='col-'] {
        padding-left: $narrow-gutter;
        padding-right: $narrow-gutter;
        margin-bottom: $narrow-gutter;

        @media (max-width: $screen-sm-max) {
            //margin-bottom: math.div($grid-gutter-width, 2);
        }
    }

    .Box {
        margin-top: math.div($narrow-gutter, 2) !important;
        margin-bottom: math.div($narrow-gutter, 2) !important;
    }

    @media (max-width: 600px) {
        [class*='col-xs-'] {
            width: 100%;
        }
    }
}

.row--equal {
    &-md-col,
    &-sm-col,
    &-lg-col {
        &-5 {
            width: percentage(math.div(1, 5));
        }
    }

    &-md-col,
    &-sm-col,
    &-lg-col {
        &-6 {
            width: percentage(math.div(1, 6));
        }
    }

    &-md-col,
    &-sm-col,
    &-lg-col {
        &-7 {
            width: percentage(math.div(1, 7));
        }
    }

    &-md-col,
    &-sm-col,
    &-lg-col {
        &-8 {
            width: percentage(math.div(1, 8));
        }
    }

    &-md-col,
    &-sm-col,
    &-lg-col {
        &-9 {
            width: percentage(math.div(1, 9));
        }
    }
}

@media (min-width: 600px) {
    .row--equal-xs {
        @include equalflex();
    }
}

@media (min-width: $screen-sm) {
    .row--equal-sm {
        @include equalflex();
    }
}

@media (min-width: $screen-md) {
    .row--equal-md {
        @include equalflex();
    }
}

.row--collapse-xs {
    @media (max-width: $screen-xs-max) {
        margin-left: math.div(-$grid-gutter-width, 2) !important;
        margin-right: math.div(-$grid-gutter-width, 2) !important;

        > [class*='col-'] {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
}
