html {
    font-size: 10px;
    font-weight: 400;
}

p {
    margin: 0 0 $spacing-3;
    @media (min-width: $screen-sm) {
        margin: 0 0 $spacing-5;
    }
    max-width: 700px;
}

p,
li,
dt,
dd {
    &.strong,
    strong {
        font-weight: 700;
    }
    // S1 Normal
    &.small,
    small {
        font-size: $size-font-xxsmall;
        line-height: $size-lineheight-small;
        font-weight: 500;
        &.strong,
        strong {
            font-weight: 700;
        }
    }
    &.large {
        font-family: NordeaSansLarge, sans-serif;
        font-size: 18px;
        line-height: $size-lineheight-small;
        font-weight: 500;
    }
}
.paragraph--large {
    font-family: NordeaSansLarge, sans-serif;
    font-size: 18px;
    line-height: $size-lineheight-small;
    font-weight: 500;
}
.paragraph--small {
    font-size: $size-font-xsmall;
    line-height: $size-lineheight-xsmall;
    font-weight: 500;
}

// Headers

.display,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    color: $color-primary-1-base;
    margin: 0 0 $spacing-5;
    &.is-compact {
        margin: 0;
    }
}

.display {
    font-family: NordeaSansLarge, sans-serif;
    font-size: $size-font-xgiant;
    line-height: $size-lineheight-xgiant;
    font-weight: 700;
    @media (max-width: $screen-sm-max) {
        font-size: $size-font-xxlarge;
        line-height: $size-lineheight-xxlarge;
    }
}

h1,
.h1 {
    font-family: NordeaSansLarge, sans-serif;
    font-size: $size-font-xlarge;
    line-height: $size-lineheight-giant;
    font-weight: 700;
    @media (max-width: $screen-sm-max) {
        font-size: $size-font-large;
        line-height: $size-lineheight-xlarge;
    }
}

h2,
.h2 {
    font-family: NordeaSansLarge, sans-serif;
    font-size: $size-font-large;
    line-height: $size-lineheight-xxlarge;
    font-weight: 700;
    @media (max-width: $screen-sm-max) {
        font-size: $size-font-medium;
        line-height: $size-lineheight-xlarge;
    }
}

h3,
.h3 {
    font-family: NordeaSansLarge, sans-serif;
    font-size: $size-font-medium;
    line-height: $size-lineheight-medium;
    font-weight: 700;
}

h4,
.h4 {
    font-family: NordeaSansLarge, sans-serif;
    font-size: $size-font-small;
    line-height: $size-lineheight-small;
    font-weight: 700;
}

h5,
.h5 {
    font-family: NordeaSansLarge, sans-serif;
    font-size: $size-font-small;
    line-height: $size-lineheight-large;
    font-weight: 500;
    // text-transform: uppercase;
    @media (max-width: $screen-sm-max) {
        font-size: $size-font-xsmall;
        line-height: $size-lineheight-medium;
    }
}

h6,
.h6 {
    font-family: NordeaSansLarge, sans-serif;
    font-size: $size-font-xsmall;
    line-height: $size-lineheight-small;
    font-weight: 500;
}
