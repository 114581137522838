.flos-input {
    border-color: $formfield-input-bordercolor;
    border-radius: $formfield-input-border-radius;
    border-style: solid;
    border-width: 1px;
    color: $formfield-input-color;
    font-weight: $formfield-font-weight--focus;
    margin: 0;
    padding: $spacing-6 $spacing-5 $spacing-3 $spacing-5;
    transition: border-color $formfield-transition-duration $formfield-timing-function;
    width: 100%;
    position: relative;

    &:disabled {
        background-color: $formfield-input-bgcolor--disabled;
        border: 1px solid $formfield-input-bordercolor--disabled;
        color: $formfield-input-color--disabled;

        & ~ * {
            color: $formfield-input-color--disabled;
            stroke: $formfield-input-color--disabled;
        }
    }

    &:not([disabled]):hover,
    &:focus {
        border-color: $formfield-input-bordercolor--active;
    }
    &:read-only {
        cursor: unset;
    }
    &:focus {
        outline: none;

        .autocomplete-container & {
            &:not(:placeholder-shown) {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }

    &::placeholder {
        color: transparent;
    }

    &:placeholder-shown {
        & ~ .flos-label {
            transform: translate3d(0, 45%, 0) scale3d(1, 1, 1);
        }
    }

    &:not([readonly]):focus {
        & ~ .flos-label {
            transform: translate3d(0, 0, 0) scale3d(0.75, 0.75, 1);
        }
    }

    .has-icon & {
        padding-left: $formfield-icon-size + ($spacing-5 * 2);
    }

    .has-chevron &,
    .has-edit-button & {
        padding-right: $formfield-icon-size + ($spacing-5 * 2);
    }

    .has-error & {
        border-color: $formfield-input-bordercolor--invalid;
    }

    .has-success & {
        border-color: $formfield-input-bordercolor--valid;
    }

    &:read-only:focus {
        border-color: $color-secondary-2-light;
    }

    &--file-upload {
        padding: 0;
        border: 1px solid $color-secondary-2-light;

        &::-webkit-file-upload-button {
            background-color: transparent;
            border-radius: $formfield-input-border-radius;
            border: 1px solid $color-primary-2-base;
            color: $color-primary-1-base;
            font-size: $size-font-base;
            font-weight: $formfield-font-weight;
            line-height: $size-lineheight-base;
            margin: $spacing-3 $spacing-5 $spacing-3 $spacing-3;
            padding: calc(#{$spacing-3} - 1px) $spacing-5;
            text-align: center;

            &:hover {
                background-color: $color-primary-2-light;
            }
        }

        &:disabled {
            color: $color-supplement-grey-dark;

            &::-webkit-file-upload-button {
                background-color: transparent;
                border: 1px solid $color-supplement-grey-medium;
                color: $color-supplement-grey-medium;
            }
        }
    }
}

.flos-label {
    color: $formfield-label-color;
    display: block;
    font-size: $size-font-xsmall;
    font-weight: $formfield-font-weight;
    left: $spacing-5;
    line-height: $size-lineheight-small;
    margin-bottom: 0;
    position: absolute;
    top: $spacing-3;
    transform-origin: top left;
    transform: translate3d(0, 0, 0) scale3d(0.75, 0.75, 1);
    transition-duration: $formfield-transition-duration;
    transition-property: transform;
    transition-timing-function: $formfield-timing-function;

    .has-icon & {
        left: $formfield-icon-size + ($spacing-5 * 2);
    }
}

.flos-field {
    margin-bottom: $spacing-5;
    transition: margin-bottom 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    position: relative;

    &--with-helptext {
        margin-bottom: ($spacing-5 * 2) + $spacing-2;
    }

    .flos-button-menu {
        display: flex;
        flex-direction: column;
        gap: 8px;

        @media (min-width: $screen-md) {
            flex-direction: row;
            gap: 16px;
            flex-wrap: wrap;
        }

        .flos-button {
            margin: 0;
        }

        p {
            max-width: 100%;
        }
    }

    .flos-button-toolbar {
        justify-content: flex-end;

        p {
            max-width: 100%;
        }
    }

    &.has-button {
        display: flex;
        padding: $spacing-3;
        border-radius: $border-radius-small;
        background-color: $color-supplement-white-base;
        border: 1px solid $color-secondary-2-light;
        width: 100%;

        .flos-input {
            border: none;
            padding: $spacing-4 $spacing-3;
            &:placeholder-shown {
                & ~ .flos-label {
                    transform: translate3d(0, 50%, 0) scale3d(1, 1, 1);
                }
            }
            &:not([readonly]):focus ~ .flos-label {
                transform: translate3d(0, 0, 0) scale3d(0.75, 0.75, 1);
            }
        }

        .flos-button {
            justify-self: flex-end;
            width: auto;
        }
    }
}

.flos-field {
    &-icon {
        position: absolute;
        pointer-events: none;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        transition: transform 0.15s $formfield-timing-function;
        left: $spacing-5;
        width: $formfield-icon-size !important;
        height: $formfield-icon-size !important;

        &--secondary {
            left: auto;
            right: $spacing-5;
        }

        &--reversed {
            transform: translate3d(0, -50%, 0) rotate(180deg);
        }

        textarea ~ & {
            top: 30px;
        }
    }

    &-edit-button {
        background-color: transparent;
        border: none;
        color: $color-secondary-2-base;
        font-weight: 500;
        position: absolute;
        text-decoration: none;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        padding: 0;
        left: auto;
        right: $spacing-5;

        flos-icon {
            float: left;
            width: $formfield-icon-size !important;
            height: $formfield-icon-size !important;
        }
    }

    &-helptext {
        &-row,
        &-col {
            float: left;
            pointer-events: none;
        }

        &-row {
            position: absolute;
            width: 100%;
            font-size: $size-font-xxsmall;
            font-weight: $formfield-font-weight--focus;
            line-height: $size-lineheight-small;
            transform: translate3d(0, $spacing-2, 0);
        }

        &-col {
            width: 50%;
            position: absolute;
            transform: translate3d(0, -100%, 0);
            opacity: 0;
            animation-fill-mode: forwards;
            animation-name: fadeInDown;
            animation-duration: $formfield-transition-duration;
            animation-timing-function: $formfield-timing-function;

            &--right {
                right: 0;
                text-align: right;
            }
        }

        &--valid {
            color: $formfield-input-bordercolor--valid;
        }

        &--invalid {
            color: $formfield-input-bordercolor--invalid;
        }
    }
}

.flos-field.u-flex-box {
    .flos-button {
        align-self: center;
    }
}

.flos-select {
    appearance: none;
    background-color: $formfield-input-bgcolor;
    border-radius: $formfield-input-border-radius;
    border: 1px solid $formfield-input-bordercolor;
    color: $formfield-input-color;
    font-weight: $formfield-font-weight--focus;
    margin: 0;
    padding: $spacing-6 $spacing-5 $spacing-3 $spacing-5;
    width: 100%;
    padding-right: $formfield-icon-size + ($spacing-5 * 2);

    &:disabled {
        background-color: $formfield-input-bgcolor--disabled;
        border: 1px solid $formfield-input-bordercolor--disabled;
        color: $formfield-input-color--disabled;
        & ~ * {
            color: $formfield-input-color--disabled;
            stroke: $formfield-input-color--disabled;
        }
    }

    &-multi-wrapper {
        border-radius: $formfield-input-border-radius;
        background-color: $formfield-input-bgcolor;
        border: 1px solid $formfield-input-bordercolor;

        &:focus-within {
            border-color: $formfield-input-bordercolor--active;
        }

        &.is-disabled {
            background-color: $formfield-input-bgcolor--disabled;
            border-color: $formfield-input-bordercolor--disabled;
        }

        &.has-error {
            border-color: $formfield-input-color--invalid;
        }

        &.has-success {
            border-color: $formfield-input-color--valid;
        }

        .flos-field-helptext-row {
            position: absolute;
        }
    }

    &.multi {
        padding-top: 0;
        border-top: $spacing-8 solid $formfield-input-bgcolor;
        border-color: $formfield-input-bgcolor;
        border-right: $spacing-5 solid $formfield-input-bgcolor;

        &:disabled {
            border-color: $formfield-input-bgcolor--disabled;
        }

        &:focus {
            border-color: $formfield-input-bgcolor;
        }

        .has-error & {
            border-color: $formfield-input-bgcolor;
        }

        .has-success & {
            border-color: $formfield-input-bgcolor;
        }
    }

    &::placeholder {
        color: transparent;
    }

    &:placeholder-shown {
        border-color: $formfield-input-bordercolor;

        & ~ .flos-label {
            top: 21px;
            font-size: $size-font-xsmall;
        }
    }

    &:focus {
        border-color: $formfield-input-bordercolor--active;
        outline: none;
    }

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background-color: $color-secondary-2-light;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $color-primary-1-base;
    }

    &:disabled::-webkit-scrollbar-track {
        background-color: $formfield-input-bordercolor--disabled;
    }

    &:disabled::-webkit-scrollbar-thumb {
        background-color: $color-supplement-grey-base;
    }

    .has-icon & {
        padding-left: $spacing-9;
    }

    .has-error & {
        border-color: $formfield-input-color--invalid;
    }

    .has-success & {
        border-color: $formfield-input-color--valid;
    }

    &.open {
        border-radius: $formfield-input-border-radius $formfield-input-border-radius 0 0;
    }

    &-arrow {
        position: absolute;
        right: $spacing-5;
        top: $spacing-5;
        transition: transform 0.3s;
        stroke: $color-primary-1-base;

        &.open {
            transform: rotate(180deg);
        }

        &.multi {
            display: none;
        }
    }

    &-option {
        color: $color-primary-1-base;
        margin: 16px 0;

        &:hover {
            background-color: $color-supplement-white-base;
        }
    }

    &-default-option {
        color: $color-supplement-grey-dark;
    }
}

option,
optgroup {
    font-size: $size-font-xsmall;
}

legend {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: $spacing-5;
    border: 0;
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }
    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

// Deprecated bootstrap radio stuff
.radio {
    display: block;
    margin-bottom: 12px;
    margin-top: 12px;
    position: relative;
    &::after {
        top: 24px;
        @media (max-width: $screen-sm-max) {
            top: 24px;
        }
    }
}
