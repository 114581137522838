.flos-list-box-list {
    .flos-list-box:not(:last-child) {
        margin-bottom: $spacing-5;
    }
}

.flos-list-box {
    padding: $spacing-5 0;
    background: $color-supplement-white-base;
    border-radius: $border-radius-medium;
    box-shadow: $shadow-small;
    border: 1px solid $color-supplement-white-base;

    &--expanded {
        padding-bottom: 0;
    }

    &--primary:hover {
        border-color: $color-primary-2-base;
    }

    &--secondary {
        border: 1px dashed $color-supplement-grey-medium;
        border-radius: 8px;
        background: $flos-list-box-secondary-bg;

        &:hover {
            border-color: $color-primary-2-base;
        }

        .flos-list-box-info {
            background-color: $flos-list-box-info-bg;
        }
    }

    &--transparent {
        border-color: transparent;
        background: transparent;
        box-shadow: none;
        .flos-list-box-header {
            padding: 0;
        }
    }

    &-header {
        padding: 0 $spacing-5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
    }

    &-description {
        padding: 0 $spacing-5 $spacing-5 $spacing-5;
        max-width: 732px;
    }

    &-subtitle {
        padding: 0;
        margin: 0;
    }

    &-icon-wrap {
        display: flex;
        align-items: center;
        gap: $spacing-5;
    }

    &-title {
        p,
        h3,
        h4 {
            margin-bottom: 0;
        }
    }

    &--has-description &-header-area.expanded,
    &--has-subtitle {
        padding-bottom: $spacing-5;
    }

    &-action {
        display: flex;
        gap: $spacing-5;
        .flos-field {
            margin: 0;
        }
    }

    &-info {
        display: flex;
        gap: $spacing-5;
        align-items: center;
        justify-content: center;
        padding: $spacing-4 $spacing-5;
        border: 1px solid $color-supplement-grey-light;
        border-radius: $border-radius-medium;
        p,
        h6,
        .h6 {
            white-space: nowrap;
            display: inline;
            margin: 0;
        }
    }

    &-addon-content {
        padding: $spacing-5 $spacing-5 0 $spacing-5;

        > .Box {
            padding: 0;
        }
    }

    &-action-area--mobile {
        padding: 0 $spacing-5;
        display: none;
    }

    &-accordion-icon {
        flos-icon {
            transition-duration: 250ms;
            color: $accordion-icon-stroke;
        }

        > .flos-button {
            padding: $spacing-3;
            width: unset;
            margin: 0;
        }

        &.expanded {
            flos-icon {
                transform: rotate(180deg);
            }
        }
    }
}

@media (max-width: $screen-sm-max) {
    .flos-list-box-header {
        flex-direction: column;
        row-gap: $spacing-5;
        > div {
            width: 100%;
        }
    }

    .flos-list-box-title {
        h3,
        .h3,
        h4,
        .h4 {
            font-size: $size-font-xsmall;
            line-height: $size-lineheight-medium;
        }
    }
    .flos-list-box-icon-wrap {
        flex-direction: row-reverse;
        width: 100%;
        justify-content: space-between;
    }

    .flos-list-box-action {
        flex-direction: column;
        row-gap: $spacing-3;
    }
    .flos-list-box-addon-content {
        padding-top: $spacing-3;
    }

    // transparent
    .flos-list-box--transparent {
        .flos-list-box-header {
            flex-direction: row;
            > div {
                width: auto;
            }
        }
        .flos-list-box-icon-wrap {
            flex-direction: row;
            width: auto;
            justify-content: start;
        }
        .flos-list-box-action {
            flex-direction: row;
        }
    }
}
