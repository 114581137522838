.flos-carousel {
    display: flex;
    height: auto;
    gap: $spacing-5;
    overflow: auto hidden;
    overscroll-behavior-x: contain;
    scroll-snap-type: x mandatory;
    padding-bottom: $spacing-2;

    &::-webkit-scrollbar {
        height: $spacing-3;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $color-supplement-grey-base;
        border-radius: $border-radius-small;
    }

    @media (any-hover: none) {
        &::-webkit-scrollbar {
            display: none;
        }
    }

    > * {
        flex: 1;
    }

    @media (max-width: $screen-sm-max) and (any-hover: none) {
        > * {
            scroll-snap-align: center;
            flex: 0 0 calc((100vw - (2 * 10vw)) - (2 * $spacing-5));

            &:first-child {
                margin-left: calc(20vw - (2 * $spacing-5));
            }
            &:last-child {
                margin-right: calc(20vw - (2 * $spacing-5));
            }
        }
    }
}
