//
// Buttons
// --------------------------------------------------
@mixin button-variant($textColor, $textColorActive, $background, $backgroundHover, $borderColor) {
    color: $textColor;
    background-color: $background;
    border-color: $borderColor;

    &:focus {
        color: $textColorActive;
        background-color: $backgroundHover;
    }

    &:hover {
        background-color: $backgroundHover;
        color: $textColorActive;
    }
    &:active,
    &.active,
    .open > &.dropdown-toggle {
        color: $textColorActive;
        background-color: $backgroundHover;

        &:hover,
        &:focus,
        &.focus {
            color: $textColorActive;
            background-color: $backgroundHover;
        }
    }
    &:active,
    &.active,
    .open > &.dropdown-toggle {
        background-image: none;
    }
    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &,
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: $backgroundHover;
            border-color: $borderColor;
        }
    }
}

// Button sizes
@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
    padding: $padding-vertical $padding-horizontal;
    font-size: $font-size;
    line-height: $line-height;
    border-radius: $border-radius;
}

// Base styles
// --------------------------------------------------

.btn {
    font-weight: 300;
    transition: background 0.15s;
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border: 1px solid transparent;
    @include button-size($spacing-3, 60px, $size-font-base, $line-height-base, $spacing-6);
    @include user-select(none);

    &:active {
        transition: none;
        box-shadow: none;
    }

    &:hover,
    &:focus,
    &.focus {
        color: $color-supplement-white-base;
        text-decoration: none;
    }

    &:active,
    &.active {
        background-image: none;
        outline: 0;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        cursor: $cursor-disabled;
        opacity: 0.4;
        @include box-shadow(none);
    }

    &-fat {
        min-width: 100px;
    }

    &-wide {
        min-width: 160px;
    }
    &-wider {
        min-width: 200px;
    }
    &-widest {
        min-width: 240px;
    }
    flos-icon {
        margin-right: 12px;
        vertical-align: text-bottom;
        color: currentColor;
    }

    @media (max-width: $screen-xs + 1) {
        white-space: normal;
        padding-left: 20px;
        padding-right: 20px;
    }
}

// $textColor, $textColorActive, $background, $backgroundHover, $borderColor
.btn-default {
    @include button-variant(#fff, #fff, $color-secondary-2-base, #0000ff, transparent);
}
.btn-primary {
    @include button-variant(#fff, #fff, $color-secondary-2-base, #0000ff, transparent);
}
.btn-primary-alt {
    @include button-variant(#fff, #fff, $color-secondary-2-base, $color-primary-1-base, transparent);
}
.btn-outline {
    border-width: 2px;
    @include button-variant($color-secondary-2-base, $color-secondary-2-base, transparent, $color-secondary-4-base, $color-secondary-2-base);
}
.btn-primary-outline {
    border-width: 2px;
    @include button-variant($color-primary-1-base, $color-primary-1-base, transparent, $color-secondary-4-base, $color-primary-1-base);
}
.btn-dark {
    @include button-variant(#fff, $color-primary-2-base, $color-primary-1-base, $color-primary-1-base, transparent);
}
.btn-light {
    @include button-variant($color-secondary-2-base, $color-primary-2-base, $color-secondary-4-base, $color-primary-1-base, transparent);
}
.btn-white-outline {
    border-width: 2px;
    @include button-variant($color-secondary-2-base, $color-secondary-2-base, transparent, $color-secondary-4-base, $color-secondary-2-base);
}
.btn-white {
    border-width: 2px;
    @include button-variant($color-secondary-2-base, $color-secondary-2-base, transparent, transparent, transparent);
}
.btn-success {
    @include button-variant(#fff, $color-primary-2-base, $color-primary-1-base, $color-primary-1-base, transparent);
}
.btn-info {
    @include button-variant(#fff, $color-primary-2-base, $color-secondary-2-base, $color-primary-1-base, transparent);
}

.btn-grey {
    @include button-variant($color-secondary-2-base, $color-primary-1-base, $color-supplement-grey-light, $color-secondary-4-base, transparent);
}

a.btn {
    &.disabled,
    fieldset[disabled] & {
        pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
    }
}

// Button Sizes
// --------------------------------------------------
// $padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius
.btn-lg {
    // line-height: ensure even-numbered height of button next to large input
    @include button-size(10px, 12px, 14px, initial, 24px);
}
.btn-sm {
    // line-height: ensure proper height of button next to small input
    @include button-size(5px, 10px, 12px, initial, 24px);
}
.btn-xs {
    @include button-size(1px, 5px, 12px, 12px, 24px);
}
