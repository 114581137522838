$hero-base-screen-width-break: 500px;
$hero-bg-overlay-primary: rgba(83, 117, 155, 0.8) !default;
$hero-bg-overlay-primary-alt: rgba(63, 122, 153, 0.8) !default;
$hero-bg-overlay-dark: $color-primary-1-base;
$hero-bg-text-primary: #131947;
$hero-base-width: 1280px;
$hero-base-height: 646px;

.Hero {
    height: auto;
    min-height: 300px;
    max-height: 1200px;

    @media (min-width: $screen-xs) {
        min-height: calc(100vh - 5%);
    }
    @media (max-width: $screen-xs-max) {
        min-height: calc(100vh - 10%);
    }
    @media (min-width: 900px) {
        min-height: calc(100vh - 10%);
    }
    @media (min-width: $screen-lg) {
        min-height: calc(100vh - 10%);
    }

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    overflow: hidden;
    background-color: $color-secondary-4-base;

    @media (max-width: $hero-base-screen-width-break) {
        height: auto;
    }

    .Breadcrumb {
        @media (min-width: $screen-md) {
            position: absolute;
            left: 0;
            right: 0;

            + .container {
                padding-top: 30px;
            }
        }
    }

    &:not(.Hero--custom) .container {
        align-self: center;

        @media (max-width: 1200px) {
            width: 100%;
        }
    }

    .lead {
        font-size: $size-font-medium;
        line-height: $size-lineheight-xlarge;
        font-weight: 300;
        margin-bottom: 4vh;
    }

    &[id] {
        scroll-margin-top: 100px;
    }

    &-bg-fit {
        position: absolute;
        z-index: 1;
        object-fit: cover;
        width: 100%;
        height: 100%;
        bottom: 0;
    }

    &-bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $hero-bg-overlay-primary;
    }

    &-custom-content {
        position: relative;
        z-index: 100;
        padding-top: $spacing-13;
        padding-bottom: $spacing-9;
    }

    &-box {
        color: $color-supplement-white-base;
        position: relative;
        z-index: 100;
        background: $hero-bg-overlay-primary 0 0 no-repeat padding-box;
        margin-top: 5vh;
        margin-bottom: 10vh;
        overflow: hidden;
        padding: $spacing-7;

        .label {
            margin-bottom: 0;
            display: inline-block;
        }

        a:not(.flos-button) {
            color: $color-primary-1-base;
            &:hover {
                color: $nordea-blue-vivid;
            }
        }

        &-header {
            margin-bottom: 2.5vh;

            @media (max-width: $screen-xs-max) {
                margin-bottom: 2vh;
            }

            @media (min-width: $screen-sm) {
                padding-right: 30px;
            }
            &--slim {
                color: $color-primary-1-base;
                font-weight: 300;
                font-size: $size-font-xlarge;
            }
        }

        &-content {
            margin-bottom: 3.5vh;
            max-width: $hero-base-screen-width-break;

            @media (max-width: $screen-xs-max) {
                margin-bottom: 3vh;
                max-width: 450px;
            }
        }

        &-note {
            font-size: $size-font-xxsmall;
            margin: 15px 0 0;
        }
    }

    /*
  **	Variants
  */

    &--pos-left {
        background-position: 0% center;
        background-attachment: fixed;
    }

    &--pos-right {
        background-position: 20% center;
        .Hero-box-header--slim {
            text-align: right;
        }
    }

    // Background colors.
    &--bg-default {
        background: $hero-bg-overlay-primary;
    }

    &--bg-primary-alt {
        .Hero-bg {
            background: $hero-bg-overlay-primary-alt;
        }
    }

    &--bg-light {
        color: $color-primary-1-base;

        .Hero-box {
            color: inherit;
            background-color: $color-secondary-4-base;
        }
    }

    &--bg-dark {
        background: $hero-bg-overlay-dark;

        .Hero-box > .flos-button,
        .Hero-box > .flos-button {
            background-color: $color-primary-2-base !important;
        }
        .Hero-box-content {
            color: $color-supplement-white-base;
        }
    }

    &--bg-transparent {
        text-shadow: $shadow-large;

        .Hero-box {
            color: $color-supplement-white-base;
            background-color: transparent;
        }
        picture:after {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8) 100%);
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            z-index: 2;
        }
    }

    @media (min-width: 900px) {
        &-box {
            h2 {
                margin-bottom: 30px;
            }

            .Hero--pos-right & {
                float: right;
            }
        }
    }

    /*
  **	Alternativ version.
  */

    &--alt {
        height: auto;
        flex-flow: column;
        background-color: #fff;
        border-bottom: 100px solid $color-supplement-grey-light;

        @media (max-width: $screen-xs-max) {
            border-bottom: none;
        }

        &:not(.Hero--bg-dark) {
            .Hero-box-content,
            .Hero-box-note {
                a {
                    color: $color-primary-1-base;

                    &:hover {
                        color: lighten($color-primary-1-base, 8%);
                    }
                }
            }
        }

        .container {
            margin-top: auto;
            margin-bottom: auto;
        }

        &.Hero--bg-default {
            background: $color-secondary-4-base;
        }

        &.Hero--bg-primary,
        &.Hero--bg-dark {
            background: $color-primary-1-base;
        }

        &.Hero--bg-primary-alt {
            background: $color-secondary-2-base;
        }

        &.Hero--bg-light {
            color: $color-primary-1-base;
            background: $color-supplement-white-base;
        }

        &.Hero--pos-right {
            .col-content {
                margin: 0 auto;

                @media (min-width: 900px) {
                    margin: 0;
                    left: 145px;
                }
            }

            @media (min-width: 900px) {
                .Media--image {
                    left: 0;
                    right: auto;

                    &::before {
                        left: auto;
                        right: -70px;
                    }
                }
            }
        }

        @media (max-width: $screen-sm-max) {
            height: auto;
            max-height: 100%;

            .col-content {
                width: 100%;
            }
        }

        .media-col {
            max-width: 630px;
            margin: 0 auto;
            position: relative;

            @media (min-width: $screen-sm) {
                height: auto;
                display: flex;
                align-items: center;
                justify-items: center;
            }

            @media (max-width: $screen-xs-max) {
                padding-bottom: 5vh;
            }
        }

        .Media {
            width: 100%;
            flex: 1 1 auto;
            min-height: 230px;
            z-index: 100;
            background-color: inherit;

            &--video {
                min-height: 0;
                border-radius: 2px;
            }

            &--image {
                display: block;
            }

            @media (max-width: $hero-base-screen-width-break) {
                background-size: cover;
                background-position: center;
                position: relative;
                overflow: hidden;

                &::before {
                    position: absolute;
                    bottom: -30px;
                    left: 0;
                    right: -10px;
                    height: 60px;
                    content: '';
                    background-color: inherit;
                    transform: rotate(4deg);
                    transform-origin: 0 0;
                }

                &:last-child {
                    &::before {
                        background-color: $color-supplement-white-base;
                    }
                }
            }

            @media (min-width: $hero-base-screen-width-break) {
                &--image {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    opacity: 0.2;
                    background-size: cover;
                    background-position: center right;
                    z-index: 0;
                }
            }

            @media (min-width: $screen-md) {
                &--image {
                    width: 50%;
                    max-width: 900px;
                    overflow: hidden;
                    border-radius: 0;
                    box-shadow: none;
                    opacity: 1;
                    background-repeat: no-repeat;
                    background-color: inherit;

                    &::before {
                        width: 150px;
                        height: 110%;
                        position: absolute;
                        left: -150px;
                        top: 0;
                        bottom: 0;
                        content: '';
                        transform: rotate(-10deg);
                        background-color: inherit;
                        transform-origin: 0 0;
                    }
                }
            }
        }

        .Hero-box {
            @media (max-width: $hero-base-screen-width-break) {
                padding-top: 10px;
            }

            &-price {
                font-size: $size-font-base;
                font-weight: 300;
                margin: 3vh 0 4vh;

                @media (min-width: $screen-md) {
                    font-size: $size-font-xlarge;
                }

                em {
                    font-weight: bold;
                    font-style: normal;
                }
            }

            @media (min-width: 768px) {
                .Hero-box {
                    max-width: 600px;
                }
                .Hero-box-header {
                    padding-right: 0;
                }
            }
        }
    }

    &:not(.Hero--alt) {
        .container {
            .no-flexbox & {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}
