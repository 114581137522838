.flos-link {
    &--has-arrow,
    &--has-icon > span:only-child {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: $spacing-5;
    }
    &.flos-link--has-icon-only {
        display: inline-block;

        & > span:only-child {
            border: 1px solid $flos-link-icon-color;
            border-radius: $border-radius-small;
            padding: 8px;
            width: unset;
        }
    }

    &-arrow,
    &-icon {
        color: $flos-link-icon-color;
    }

    &--boxlink {
        border-radius: $border-radius-small;
        &:hover,
        &:active {
            background: $color-secondary-2-light;
        }
    }

    //themes
    &--logo {
        color: $flos-link-logo-color;
        &:hover {
            color: $flos-link-logo-color;
            text-decoration: none;
        }
    }

    &--primary {
        color: $color-primary-1-medium;
    }

    &--external {
        // color: $flos-link-text-color--external;
        .flos-link-icon {
            color: $color-primary-1-dark;
        }
    }
}
