.navbar-segments {
    height: 34px;
    position: relative;
    @include z-index(site-header);
    background: $color-primary-1-base;

    /*
	* Added for "Erhverv" login dropdown
	*/
    &-login {
        color: $color-supplement-white-base;
        line-height: 34px;
    }

    a {
        color: $color-supplement-white-base;
        display: block;
        line-height: 34px;
        position: relative;
    }

    ul {
        list-style: none;
        margin: 0 auto;
        padding: 0;

        @media (min-width: $screen-md) {
            padding-left: 170px;
        }

        @media (min-width: 1100px) {
            padding-left: 175px;
        }

        @media (min-width: $screen-lg) {
            padding-left: 220px;
        }
    }

    li {
        display: inline-block;
        width: 33%;
        margin: 0;
        text-align: center;
        margin-right: -4px;

        &.active a {
            font-weight: bold;
        }

        a {
            font-weight: 300;
            font-size: $size-font-base;
        }

        @media (min-width: $screen-md) {
            margin-right: 40px;
            width: auto;
            text-align: left;
        }
    }

    &--collapse {
        display: none;
        margin: 0 -15px;
        padding: 0;
        font-size: $size-font-xxsmall;
        height: 35px;

        a {
            line-height: 35px;
        }

        ul {
            padding: 0;
            display: flex;
            justify-content: space-around;
        }
    }
}
