.info-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: $spacing-7;
    border-radius: $border-radius-medium;
    background-color: transparent;
    box-shadow: none;
    border: 1px solid $color-secondary-4-base;
    color: $color-primary-1-dark;
    padding: $spacing-7;
    @media (max-width: $screen-sm-max) {
        padding: $spacing-5;
    }

    &-icon,
    &-image {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        width: 100%;
        position: relative;
        height: 64px;

        @media (min-width: $screen-md) {
            height: 128px;
        }
    }
    &-icon {
        flos-icon {
            height: $spacing-11 !important;
            width: $spacing-11 !important;
            color: $color-primary-1-dark;
        }
        &--large flos-icon {
            @media (min-width: $screen-md) {
                height: $spacing-13 !important;
                width: $spacing-13 !important;
            }
        }
    }

    &-image {
        img {
            height: $spacing-11;
            max-width: 100%;
            object-fit: cover;
        }
        &--large img {
            @media (min-width: $screen-md) {
                height: $spacing-13;
            }
        }
    }

    &-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: $spacing-5;
        @media (min-width: $screen-md) {
            gap: $spacing-7;
        }
        .info-box-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            .info-box-label {
                margin-bottom: $spacing-3;
            }
            p {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .info-box-action {
        display: flex;
        flex-direction: column;
        align-self: flex-end;
    }

    // Variants
    &--align-left {
        align-items: flex-start;

        .info-box-image,
        .info-box-icon {
            justify-content: flex-start;
            align-self: flex-start;
        }
        .info-box-content {
            .info-box-text {
                align-items: self-start;
                text-align: start;
            }
        }
    }

    // themes
    &--theme-white {
        background-color: $color-supplement-white-base;
        box-shadow: $shadow-small;
        border: none;
    }
    &--theme-no-border {
        border: none;
    }
    &--theme-link {
        padding: $spacing-7;
        background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' style='stroke:%2363aedb;fill:none;stroke-width:8px;' width='16' height='16'  aria-hidden='true'><path d='m24.4 49.068 15.303-17.004L24.4 15.061'></path></svg>")
            no-repeat calc(100% - 16px) calc(100% - 16px);
        &:hover {
            cursor: pointer;
            background-color: $color-primary-2-light;
        }
    }
}
