.AttentionBox {
    display: flex;
    padding: $spacing-5 0;
    color: $nordea-black;
    position: relative;
    border-radius: $border-radius-small;
    background-color: $nordea-blue-light;
    margin-bottom: $spacing-5;

    .PageHeader & {
        margin-top: -5px;
        background-color: rgba(#000, 0.3);
        margin-bottom: 30px;

        @media (min-width: $screen-md) {
            margin-top: -40px;
            margin-top: -4vmin;
            margin-bottom: 60px;
        }
    }

    @media (min-width: $screen-md) {
        padding-top: $spacing-6;
        padding-bottom: $spacing-6;
        font-size: $size-font-base;
    }

    .container {
        max-width: 1200px;
        width: 100%;
        padding-left: $spacing-5;
        padding-right: $spacing-5;
        position: relative;
        display: flex;
        align-items: center;
        margin: 0;

        @media (min-width: $screen-md) {
            padding-left: $spacing-6;
            padding-right: $spacing-6;
        }
    }

    p {
        width: 100%;
        max-width: none;
        margin: 0;
        overflow: hidden;
        line-height: 1.3;
        flex: 1 1 auto;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $nordea-black;
    }

    &-ico {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 2px solid $nordea-black;
        display: inline-block;
        text-align: center;
        font-size: 30px;
        font-weight: bold;
        line-height: 36px;
        font-family: arial, sans-serif;
        float: left;
        margin-right: 17px;
        margin-top: 4px;
        flex: 0 0 auto;
        align-self: flex-start;
    }

    flos-icon {
        margin-right: $spacing-5;
        color: $nordea-black;
    }

    &-close {
        float: right;
        margin-left: 10px;
        margin-bottom: 5px;
        display: inline-block;
        flex: 1 0 auto;
        line-height: 1;
        transition: opacity 0.15s;
        align-self: flex-start;

        &:hover {
            opacity: 0.8;
        }

        &:active {
            transform: translateY(1px);
        }

        flos-icon {
            position: relative;
            top: -2px;
            color: currentColor;
        }
    }

    .no-flexbox & {
        &-ico {
            float: left;
        }

        p {
            width: 90%;
            padding-right: 50px;
        }

        &-close {
            position: absolute;
            top: 1px;
            right: 10px;
        }
    }
}
