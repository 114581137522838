@use 'sass:math';

.autocomplete-container {
    position: relative;
}
$scrollbar-width: ($spacing-5 * 2) + 4px;
.dawa-autocomplete {
    &-suggestions {
        background-color: $color-supplement-white-base;
        background-color: $formfield-input-bgcolor;
        border-color: $formfield-input-bordercolor--active;
        border-radius: 0;
        border-bottom-left-radius: $formfield-input-border-radius;
        border-bottom-right-radius: $formfield-input-border-radius;
        border-style: solid;
        border-width: 1px;
        color: $color-primary-1-base;
        display: block;
        font-weight: $formfield-font-weight;
        max-height: 176px;
        overflow-y: scroll;
        padding: 0;
        position: relative;
        width: 100%;

        &::-webkit-scrollbar {
            width: $scrollbar-width;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 $scrollbar-width $scrollbar-width $color-supplement-grey-base;
            border: solid $spacing-5 transparent;
        }

        &::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 $scrollbar-width $scrollbar-width $color-primary-1-base;
            border-width: math.div($spacing-5, 2) $spacing-5 math.div($spacing-5, 2) $spacing-5;
            border-style: solid;
            border-color: transparent;
        }
    }
    &-suggestion {
        margin: 0;
        list-style: none;
        cursor: pointer;
        padding: $spacing-4;
        color: $color-primary-1-base;
        font-weight: 400;
        border-radius: $formfield-input-border-radius;
        border: 1px solid transparent;
        &:hover {
            background-color: $color-secondary-2-light;
        }
        &:focus {
            outline: none !important;
            border-color: $color-secondary-2-base;
            background-color: $color-secondary-2-light;
        }
    }
    &-suggestions &-suggestion.dawa-selecte {
        background-color: $color-secondary-2-light;
    }
}
