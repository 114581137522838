$default: transparent; // enabled
$active: $color-secondary-2-light; // focus
$fill: $color-secondary-2-light; // checked
$filled: $color-secondary-2-medium; // checked & validated
$hover: $color-secondary-2-medium; // missing in spec.

.CheckBox {
    display: block; // Label element.
    font-weight: 400;
    border: 1px solid $color-secondary-2-light;
    border-radius: 2px;
    padding-left: $spacing-9;
    cursor: pointer;

    @media (min-width: $screen-xs) {
        padding-left: $spacing-11;
    }

    &.Box {
        color: $color-secondary-2-dark;
        background-color: transparent;
        padding: $spacing-5 $spacing-5 $spacing-5 $spacing-10;
        h4 {
            font-size: 16px;
        }
        &:nth-child(2) {
            font-weight: 300;
        }
    }

    .Box-header {
        font-size: $size-font-base;
        line-height: $size-lineheight-base;
        hyphens: auto;
        word-break: break-word;
        margin-bottom: 0;
        color: $color-secondary-2-base;
        @media (min-width: $screen-sm) {
            font-size: $size-font-small;
            line-height: 26px;
        }
    }

    p.error-text {
        font-size: $size-font-xxsmall;
        color: $color-supplement-error-base;
        margin-top: -6px;
        margin-bottom: 0;
    }

    &::before {
        content: '';
        display: inline-block;
        width: 24px;
        height: 24px;
        border: 1px solid $color-secondary-2-base;
        background: $default;
        position: absolute;
        left: $spacing-5;
        transition: 0.2s;
        background-clip: content-box;
        padding: 1px;

        // @media (min-width: $screen-sm) {
        //     left: 29px;
        // }

        // @media (min-width: $screen-xs) {
        //     left: 25px;
        // }
    }

    @media (max-width: $screen-sm) {
        & .Box-header {
            font-size: $size-font-small;
        }
    }

    &:hover,
    input[type='checkbox']:focus ~ & {
        background-color: $color-secondary-2-light;
        border-color: $color-secondary-2-light;
        color: $color-primary-1-base;

        a:not(.CheckBox-more) {
            color: inherit;
        }
        h4 {
            color: $color-primary-1-base;
        }
    }

    &.is-active,
    input[type='checkbox']:checked ~ & {
        background-color: $color-secondary-2-light;
        border-color: $color-primary-2-medium;
        color: $color-primary-1-base;

        box-shadow: none;

        h4 {
            color: $color-primary-1-base;
        }

        &::before {
            @extend .ico-tick-white;
            border-color: $color-primary-1-base;
            background-position: center;
            background-color: $color-secondary-2-base;
            background-size: $spacing-5;
            background-repeat: no-repeat;
        }

        &:active {
            background-color: $active;
        }

        .CheckBox-more {
            color: transparent;
        }
    }

    input[type='checkbox']:checked:focus ~ & {
        background-color: $color-secondary-2-light;
    }

    &-more {
        &.is-active {
            color: $color-secondary-2-light;
        }
    }

    &--conditions {
        margin-top: 5px;
        min-height: 0;
    }

    &.has-error {
        border-color: $color-supplement-error-light;
    }
}
