.Box--usp {
    @extend .Box--link;

    &:hover,
    &:focus,
    &:active {
        background: $color-secondary-4-base;
    }

    &.is-selected {
        background: $color-secondary-4-base;
    }

    .Box-header {
        margin-bottom: 10px;
        font-weight: 700;
        font-size: 18px;
        line-height: $size-lineheight-small;
        color: $color-secondary-2-base;

        a {
            color: inherit;
            font-weight: inherit;
            line-height: inherit;
            font-size: inherit;
        }
    }

    &-placeholderLink {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 50;
    }

    &-note {
        position: relative;
        z-index: 100;
        font-size: $size-font-xxsmall;
    }
    ul {
        list-style: none;
        padding-left: initial;
    }
}
