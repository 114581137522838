.cvi-button,
.flos-button {
    background-color: $button-primary-bg;
    border-radius: $spacing-6;
    border: none;
    color: $button-primary-color;
    display: block;
    font-size: $size-font-base;
    font-weight: 400;
    line-height: $size-lineheight-base;
    margin: 0;
    padding: $button-primary-padding;
    text-align: center;
    width: 100%;

    &:hover,
    &.hover,
    &:focus,
    &.focus {
        background-color: $button-primary-hover-bg;
    }

    &:active,
    &.active {
        background-color: $button-primary-active-bg;
        color: $button-primary-active-color;
    }

    &:disabled,
    &[disabled],
    &[aria-disabled='true'] {
        opacity: 40%;
        padding: $button-primary-padding;
        pointer-events: none;
    }

    & ~ .cvi-button,
    & ~ .flos-button {
        margin-top: $spacing-5;
    }

    @media (min-width: $screen-md) {
        margin: 0;
        display: inline-block;
        width: auto;
        & ~ .cvi-button,
        & ~ .flos-button {
            margin-top: 0;
            margin-left: $spacing-6;
        }
    }

    &.flos-button--large {
        padding: $button-primary-padding-large;
        &.flos-button--secondary {
            padding: $button-secondary-padding-large;
        }
    }
    &.flos-button--small {
        padding: $button-primary-padding-small;
        &.flos-button--secondary {
            padding: $button-secondary-padding-small;
        }
    }
    &.flos-button--icon-only {
        border-radius: $border-radius-small;
        padding: $spacing-3;
        width: unset;
        background-color: transparent;

        &.flos-button--small {
            padding: 8px;
        }
        &.flos-button--medium {
            padding: 8px;
        }
        &.flos-button--large {
            padding: 12px;
        }
    }
}
.flos-button--ghost {
    background-color: transparent;
    color: $button-ghost-color;
    padding: $button-ghost-padding;

    &:disabled,
    &[disabled],
    &[aria-disabled='true'] {
        background-color: transparent;
        opacity: 40%;
        padding: $button-ghost-padding;
        pointer-events: none;
    }
    &:hover,
    &.hover,
    &:focus,
    &.focus,
    &.active {
        background-color: transparent;
        color: $nordea-blue-deep;
    }

    &.flos-button--large,
    &.flos-button--medium,
    &.flos-button--small {
        padding-left: 0;
        padding-right: 0;
    }
    &.flos-button--small {
        font-size: $size-font-xxsmall;
        padding: $button-ghost-padding-small;
    }
}

.cvi-button--level1,
.flos-button--no-fill,
.cvi-button--level2,
.flos-button--outline,
.cvi-button--level3,
.flos-button--default,
.cvi-button--level4,
.flos-button--secondary {
    background-color: $button-secondary-bg;
    color: $button-secondary-color;
    border: $button-secondary-border;
    padding: $button-secondary-padding;

    &:hover,
    &.hover,
    &:focus,
    &.focus {
        background-color: $button-secondary-hover-bg;
        color: $button-secondary-hover-color;
        border: $button-secondary-hover-border;
    }

    &:active,
    &.active {
        background-color: $button-secondary-active-bg;
        color: $button-secondary-active-color;
        border-color: $button-secondary-active-bg;
    }

    &:disabled,
    &[disabled] {
        opacity: 40%;
        padding: $button-secondary-padding;
    }

    &.flos-button--icon-only {
        padding: 7px;
        &.flos-button--small {
            padding: 7px;
        }
        &.flos-button--medium {
            padding: 7px;
        }
        &.flos-button--large {
            padding: 11px;
        }
    }
}

.flos-button--icon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $spacing-3;

    flos-icon {
        color: currentColor;
    }
}

.cvi-button-toolbar,
.flos-button-toolbar {
    display: flex;

    &--stretched {
        justify-content: space-evenly;
    }

    .cvi-button,
    .flos-button {
        border-color: $color-secondary-4-base;
        margin-left: 0;
        margin-right: -1px;
        border-radius: 0;
        border-bottom-left-radius: $border-radius-small;
        border-top-left-radius: $border-radius-small;
        &.flos-button--outline {
            padding-left: $spacing-5;
            padding-right: $spacing-5;
            flex-grow: 1;
            flex-basis: 0;
        }
        & ~ .cvi-button,
        & ~ .flos-button {
            margin-top: 0;
            border-radius: 0;
        }
        &.active {
            border-color: $color-primary-2-base;
            + input + .flos-button {
                border-left-color: $color-primary-2-base;
            }
        }
    }
    & > .cvi-button,
    & > .flos-button {
        &:last-child {
            margin-right: 0;
            border-bottom-right-radius: $border-radius-small;
            border-top-right-radius: $border-radius-small;
        }
    }
}

.cvi-button-group,
.flos-button-group {
    display: flex;
    .flos-button {
        margin-top: 0;
        width: auto;
        & ~ .flos-button {
            margin-left: $spacing-5;
        }
    }
    .cvi-button {
        margin-top: 0;
        & ~ .cvi-button {
            margin-left: $spacing-5;
        }
    }
    &--stacked {
        flex-direction: column;
        & .flos-button {
            margin-bottom: $spacing-5;
            & ~ .flos-button {
                margin-left: 0 !important;
                margin-top: 0;
            }
        }
        & .cvi-button {
            margin-bottom: $spacing-5;
            & ~ .cvi-button {
                margin-left: 0 !important;
                margin-top: 0;
            }
        }
    }
    @media (min-width: $screen-sm) {
        display: inline-flex;
        min-width: 280px;
        .cvi-button,
        .flos-button {
            & ~ .flos-button,
            & ~ .cvi-button {
                margin-left: $spacing-5;
            }
        }
    }
}

.flos-button--wide {
    min-width: 140px;
}
.flos-button--wider {
    min-width: 200px;
}
.flos-button--widest {
    min-width: 240px;
}

// Legacy styling
.cvi-button {
    &-wide {
        min-width: 140px;
    }

    &-wider {
        min-width: 200px;
    }

    &-widest {
        min-width: 240px;
    }
}
