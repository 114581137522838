/*
! This CSS resource incorporates links to font software which is the valuable copyrighted property of WebType LLC,
The Font Bureau and/or their suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse
engineer this font software. Please contact WebType with any questions: http://www.webtype.com
*/

@font-face {
    font-family: NordeaSansLarge;
    src: url('../../fonts/NordeaSansLarge-Regular.woff2') format('woff2');
    font-display: swap;
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: NordeaSansLarge;
    src: url('../../fonts/NordeaSansLarge-Bold.woff2') format('woff2');
    font-display: swap;
    font-style: normal;
    font-weight: bold;
}

@font-face {
    font-family: NordeaSansSmall;
    src: url('../../fonts/NordeaSansSmall-Regular.woff2') format('woff2');
    font-display: swap;
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: NordeaSansSmall;
    src: url('../../fonts/NordeaSansSmall-Bold.woff2') format('woff2');
    font-display: swap;
    font-style: normal;
    font-weight: bold;
}
