.flos-hero {
    background-color: $color-primary-1-base;
    border-bottom: 104px solid $color-supplement-grey-light;

    &.has-splash {
        @media (max-width: $screen-xs-max) {
            border-bottom: 176px solid $color-supplement-grey-light;
        }
    }

    &[id] {
        scroll-margin-top: 130px;
    }

    .container {
        position: relative;
    }

    &:not(.flos-hero--transparent) {
        .container {
            @media (max-width: $screen-xs-max) {
                padding: 0;
                width: 100%;
            }
        }
    }

    @media (min-width: $screen-sm) {
        border-bottom-width: 208px;
        height: 718px;
        padding-top: $spacing-11;
        padding-bottom: $spacing-11;
    }

    /** Themes */
    &--secondary {
        background-color: $color-secondary-4-base;
    }

    &--transparent {
        border-bottom-width: $spacing-11;
        height: auto;
        overflow: hidden;
        position: relative;
        padding-top: $spacing-11;
        padding-bottom: $spacing-11;
        @media (max-width: $screen-sm-max) {
            border-bottom-width: $spacing-7;
            padding-top: $spacing-11;
            padding-bottom: $spacing-7;
        }

        .flos-hero-content--transparent {
            z-index: 5;
            min-height: 400px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @media (max-width: $screen-sm-max) {
                min-height: auto;
            }
        }

        .flos-hero-text {
            text-shadow: $shadow-large;
            color: $color-supplement-white-base;
        }

        .flos-hero-splash-container--transparent {
            justify-self: end;
            align-self: center;
            z-index: 5;
        }

        .flos-hero-splash {
            width: 310px;
        }

        picture:after {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8) 100%);
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            z-index: 2;
        }

        .flos-hero-bg-fit {
            position: absolute;
            z-index: 1;
            object-fit: cover;
            width: 100%;
            height: 100%;
            bottom: 0;
        }

        .flos-hero-bottom-area {
            z-index: 10;
            justify-self: center;
        }
    }
}

.flos-hero-content {
    position: relative;
    background-color: $color-supplement-white-base;
    margin-bottom: 200px;
    border-radius: 0 0 $border-radius-medium $border-radius-medium;
    box-shadow: $shadow-large;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: $spacing-7 $spacing-5 $spacing-11;
    z-index: 0;

    @media (min-width: $screen-sm) {
        position: absolute;
        top: $spacing-7;
        border-radius: $border-radius-medium;
        width: 695px;
        height: 560px;
        padding: $spacing-9;
        z-index: 10;

        @media (max-width: $screen-sm-max) {
            width: 440px;
            padding: $spacing-7;
        }
    }
}

.flos-hero-text {
    margin-bottom: $spacing-7;

    *:last-child {
        margin-bottom: 0;
    }
}

.flos-hero-label {
    margin-bottom: $spacing-3;
}

.flos-hero-title {
    margin-bottom: $spacing-3;
}

.flos-hero-subtitle {
    margin-bottom: $spacing-7;
}

.flos-hero-action {
    .flos-button-group .flos-button {
        @media (max-width: $screen-xs-max) {
            width: 100%;
        }
    }
}

.flos-hero-splash-container {
    position: absolute;
    bottom: -144px;
    height: 184px;
    width: calc(100% - 64px);
    margin-right: $spacing-7;
    margin-left: $spacing-7;

    @media (min-width: $screen-sm) {
        top: 360px;
        left: 663px;
        width: 310px;
        margin: 0;
        z-index: 60;

        @media (max-width: $screen-sm-max) {
            left: 424px;
        }
    }
    @media (max-width: $screen-xs-max) {
        display: flex;
        justify-content: center;
    }
}

.flos-hero-image-container {
    position: absolute;
    bottom: -72px;
    padding: 0 $spacing-5;
    width: auto;
    height: 308px;

    @media (min-width: $screen-sm) {
        bottom: auto;
        right: 0;
        width: 572px;
        height: 512px;
    }

    @media (max-width: $screen-xs-max) {
        display: flex;
        justify-content: center;
        width: 100%;
    }
}

.flos-hero-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: $border-radius-medium;
}
