.Accordion {
    margin-bottom: $spacing-7;

    &-panel {
        margin-bottom: $spacing-3;
    }

    flos-icon:not(.toggle-icon):not(.Accordion-toggle-icon) {
        margin: 0 10px 0 0;
    }

    .toggle-icon {
        position: absolute;
        right: $spacing-5;
        top: 50%;
        margin-top: -13px;
        transform: rotate(-90deg);
        transition: transform 0.3s;
        color: $color-primary-1-base;
    }
    &-toggle-icon {
        position: absolute;
        right: $spacing-5;
        top: $spacing-5;
        // margin-top: -$spacing-5;
        transform: rotate(180deg);
        transition: transform 0.3s;
        color: $color-primary-1-base;
    }

    &-panel-collapse {
        background-color: $color-supplement-white-base;
        border-bottom-right-radius: $border-radius-medium;
        border-bottom-left-radius: $border-radius-medium;
        box-shadow: $shadow-small;
        &.collapsed {
            border-bottom: none;
        }
    }

    &-title {
        margin: 0;
        display: block;
        position: relative;
        padding: $spacing-5;
        background-color: $color-supplement-white-base;
        border-bottom: 1px solid $color-supplement-grey-base;
        transition: all 0.2s;
        border-top-right-radius: $border-radius-medium;
        border-top-left-radius: $border-radius-medium;
        box-shadow: $shadow-small;

        & .Accordion-subtitle {
            text-transform: none;
            font-weight: 300;
        }

        h4 {
            margin-bottom: 0;
            padding-right: 40px;
            color: $color-primary-1-base;
        }

        &:active,
        &:hover,
        &:focus {
            text-decoration: none;
            outline: none !important;
            h4,
            p {
                color: $color-primary-1-base;
            }
        }

        &.collapsing {
            color: $color-primary-1-base;
            background: $color-supplement-white-base;
        }

        &.collapsed {
            background: transparent;
            border-bottom: 1px solid $color-secondary-4-base;
            box-shadow: none;
            border-top-right-radius: 0;
            border-top-left-radius: 0;

            h4 {
                color: $color-secondary-2-base;
            }

            .Accordion-toggle-icon {
                transform: none;
                color: $color-primary-1-base;
            }
            .toggle-icon {
                transform: rotate(90deg);
                color: $color-primary-1-base;
            }
            &:active,
            &:hover {
                & h4 {
                    color: $color-primary-1-base;
                }
                border-bottom: 1px solid $color-secondary-2-base;
                background-color: $color-secondary-4-light;
            }
        }
    }

    .panel-body {
        border-bottom-right-radius: $border-radius-medium;
        border-bottom-left-radius: $border-radius-medium;
        overflow: hidden;

        & > .Box {
            margin: 0;
        }
    }

    &-subtitle {
        margin-bottom: 0;
        margin-top: 5px;
        color: $color-primary-1-dark;
    }
}

.Accordion-details + .Accordion-panel {
    .Accordion-title {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }

    .Accordion-panel-collapse {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        box-shadow: none;
    }

    .panel-body {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.Accordion-details {
    margin-top: 10px;
    background-color: #fff;
    padding: $spacing-5;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px dashed $color-supplement-grey-light;
    p {
        margin-bottom: 0;
    }
    & + .Accordion-panel .Accordion-title {
        border-bottom: none;
    }
    @media (max-width: $screen-md) {
        flex-direction: column;
        align-items: stretch;
        p {
            max-width: none;
        }
    }
}
.Accordion-details-action {
    text-align: end;
    @media (max-width: $screen-md) {
        p {
            max-width: none;
            margin-top: 20px;
        }
    }
}

.Accordion.Accordion--secondary {
    margin-bottom: 0;

    .Accordion-panel {
        margin-bottom: 0;
    }

    .Accordion-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        box-shadow: none;
        border-bottom: none;

        p {
            margin: 0;
        }
    }

    .Accordion-title-txt {
        display: flex;
        align-items: center;
    }

    .panel-body {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .Accordion-toggle-icon {
        position: initial;
        margin-right: $spacing-5;
    }

    .Accordion-title.collapsed {
        border-bottom: none;
    }

    .Accordion-panel-collapse {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        box-shadow: none;
        &:not(.collapsed) {
            border-bottom: 1px solid $color-secondary-4-base;
        }
    }
}
