.boxlink-group {
    border-radius: $border-radius-medium;
    background: $color-supplement-white-base;
    box-shadow: $shadow-small;
    gap: 0;

    .boxlink-wrapper {
        @media (min-width: $screen-sm) {
            &:not(:nth-child(even)):after {
                content: '';
                width: 1px;
                height: calc(100% - 16px);
                background-color: $color-supplement-grey-light;
                position: absolute;
                right: 0;
                top: $spacing-3;
            }
        }
    }
}

.boxlink-list {
    list-style-type: none;
    padding-left: 0;

    & > li {
        margin-bottom: $spacing-5;
    }

    &--small {
        & > li {
            margin-bottom: $spacing-3;
        }
    }

    & > li:last-child {
        margin-bottom: 0;
    }
}

.boxlink-stack {
    border-radius: $border-radius-medium;
    background: $color-supplement-white-base;
    box-shadow: $shadow-small;

    .boxlink-wrapper:not(:last-child) {
        border-bottom: 1px solid $color-supplement-grey-light;
    }
}

.boxlink {
    border-radius: $border-radius-medium;
    color: $color-primary-1-base;
    background-color: $color-supplement-white-base;
    padding: $spacing-3;
    width: 100%;
    display: flex;

    & > span:only-child {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        gap: $spacing-5 $spacing-7;
    }

    &--highlight {
        background-color: $box-link-bg-color--highlight;
    }

    &--transparent {
        background-color: transparent;
    }

    &--dynamic {
        border: 1px solid $color-secondary-4-base;
        & > span {
            padding: $spacing-3;
            border-radius: $border-radius-small;
        }
        &:hover,
        &:active {
            background-color: $color-supplement-white-base !important;
        }
        &:hover > span {
            background-color: $color-secondary-2-light;
        }
        &:active > span {
            background-color: $color-primary-2-light;
        }
        &.boxlink--focus-high {
            background-color: $color-primary-2-light;
            &:hover {
                background-color: $color-primary-2-light !important;
            }
        }
    }

    &:hover,
    &:active {
        background-color: $color-secondary-2-light;
    }

    p {
        color: $box-link-description-color;
        font-weight: 300;
        &:last-child {
            margin: 0;
        }
    }

    flos-icon {
        flex-shrink: 0;
    }

    .boxlink-note {
        @media (max-width: $screen-sm-max) {
            margin-top: $spacing-5;
        }
        p {
            font-weight: 400;
            @media (max-width: $screen-sm-max) {
                font-size: $size-font-xxsmall;
            }
        }
    }

    &--focus-low .boxlink-note p {
        color: $box-link-note-text-color--focus-low;
    }

    &.has-note > span > span:first-child {
        display: block;
        width: 100%;
        & > div {
            & > div {
                width: 50%;
                @media (max-width: $screen-sm-max) {
                    width: 100%;
                }
            }
        }
    }
}

.boxlink-icon {
    color: $color-primary-1-medium;
}

.boxlink-wrapper {
    padding: $spacing-3;
    position: relative;
    -ms-grid-column-end: span 6;
    grid-column-end: span 6;
    &:not(:last-child:nth-child(odd)):not(:nth-last-child(2):nth-child(odd)):not(:last-child:nth-child(even)) {
        border-bottom: 1px solid $color-supplement-grey-light;
    }
    &:not(:last-child) {
        @media (max-width: $screen-sm-max) {
            border-bottom: 1px solid $color-supplement-grey-light;
        }
    }

    // If grouped we want min height to make sure we support 2 lines of text -> still having equal heights cross rows/cols
    &--grouped {
        > * {
            min-height: 72px;
        }
    }
}
