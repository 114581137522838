a {
    color: $color-secondary-2-base;
    text-decoration: $a-text-decoration;
    font-weight: 500;

    &:focus {
        outline: 1px dotted $color-secondary-2-dark !important;
        &.nooutline {
            outline: 0 !important;
        }
    }
}

p a:hover {
    color: $nordea-blue-vivid;
    text-decoration: underline;
}

.Go {
    transition: 0.2s;
    text-decoration: underline;

    &::before {
        content: '\203A \00a0\00a0';
        margin-right: unset;
        text-decoration: none;
        display: inline-block;
    }

    &:hover {
        color: $nordea-blue-vivid;
    }

    .ico {
        font-size: 10px;
    }
}

.LinkList {
    list-style: none;
    padding: 0;

    li > a {
        @extend .Go;
    }
}

.site-footer {
    a:focus {
        outline: 1px dotted $color-supplement-white-base !important;
    }
}
