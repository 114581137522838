.site-footer {
    background: $color-supplement-white-base;

    .flos-grid {
        gap: 32px;
        @media (min-width: $screen-md) {
            gap: 32px;
        }
    }

    h5 {
        color: $color-supplement-white-base;
        position: relative;
        margin-bottom: $spacing-5;
        padding: 0 $spacing-5 $spacing-5 $spacing-5;
        border-bottom: 1px solid transparent;
        margin-right: unset;
        text-transform: uppercase;
    }

    ul {
        list-style: none;
        padding: 0 $spacing-5;
        margin: 0;
    }

    li {
        margin: 0;
        padding: 0;
        line-height: $size-lineheight-medium;
        margin-bottom: $spacing-5;
        &:last-child {
            margin-bottom: 0;
        }
        a {
            display: block;
        }
    }
    .card {
        // We use Card's in the footer content on Mit - this it to make sure they are equal height.
        height: 100%;
        box-shadow: none;
    }

    &--mit {
        @media (max-width: $screen-sm-max) {
            margin-bottom: 60px;
        }

        h5 {
            border-bottom: 1px solid $nordea-grey-dark;
        }

        .site-footer-top .site-footer-link {
            color: $nordea-blue-medium;
        }
    }
}

.site-footer-top {
    background-color: $nordea-blue-deep;
    padding-top: $spacing-9;
    padding-bottom: $spacing-9;

    @media (min-width: $screen-md) {
        padding-top: $spacing-12;
        padding-bottom: $spacing-12;
    }

    .site-footer-link {
        color: $color-supplement-white-base;
    }
}

.site-footer-content {
    p:last-child {
        margin-bottom: 0;
    }
}

.site-footer-bottom {
    padding-top: $spacing-7;
    padding-bottom: $spacing-7;
}

.ContactBar {
    display: flex;
    flex-direction: column;
    gap: $spacing-7;
    justify-content: space-between;
    font-size: $size-font-xsmall;

    @media (min-width: $screen-sm) {
        flex-direction: row;
        align-items: center;
    }

    &-address {
        font-style: normal;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;

        span {
            display: block;
            margin-right: $spacing-4;
        }
    }

    &-links {
        line-height: $size-lineheight-small;
        margin-bottom: 0;
        order: -1;

        a {
            margin-bottom: $spacing-5;
            &:last-child {
                margin-bottom: 0;
            }
        }

        @media (min-width: $screen-sm) {
            margin-left: auto;
            order: 0;
            a {
                display: inline-block;
                margin: 0 0 0 $spacing-6;
            }
        }
    }
}
.site-footer-link {
    display: block;
    font-weight: 500;
    font-size: $size-font-xsmall;
    line-height: $size-lineheight-small;
    &:hover {
        text-decoration: underline;
    }
}
