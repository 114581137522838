.Breadcrumb {
    display: block;
    list-style: none;
    background-color: $breadcrumb-bg-color;
    position: relative;
    border-top: 1px solid $color-secondary-2-base;
    border-bottom: 1px solid $color-secondary-2-base;
    @include z-index(breadcrumb);

    &--white {
        background-color: $color-supplement-white-base;
    }

    @media (max-width: $screen-sm) {
        display: none;
    }

    &-list {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: $spacing-3;
        padding-bottom: $spacing-3;
        white-space: nowrap;
    }

    &-item {
        display: inline-block;
        margin-bottom: 0;
        &:first-child {
            .Breadcrumb-link {
                padding-left: 0;
            }
        }

        span {
            display: inline-block;
            font-weight: 300;
        }

        &.is-collapsed {
            .Breadcrumb-link {
                &::before {
                    content: '...';
                    vertical-align: text-top;
                    margin-left: 2px;
                }

                &:hover::before,
                &:focus::before {
                    color: inherit;
                }

                span {
                    width: 0;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
        }
    }

    &-link {
        position: relative;
        color: $color-secondary-2-base;
        font-size: $size-font-base;

        &::after {
            content: '>';
            position: relative;
            display: inline-block;
            padding-left: $spacing-3;
            padding-top: 1px;
            color: $color-secondary-2-base;
        }

        &:hover,
        &:focus {
            text-decoration: none;

            span {
                color: $color-primary-1-base;
                &:after {
                    color: $color-secondary-2-base;
                }
            }
        }

        &--current {
            cursor: default;

            &:hover,
            &:focus {
                text-decoration: none;
            }

            &::after {
                visibility: hidden;
            }
        }
    }
}

.flos-breadcrumb {
    display: flex;
    vertical-align: middle;
    gap: $spacing-3;
    list-style: none;
    background-color: transparent;
    position: relative;
    @include z-index(breadcrumb);
}
.flos-breadcrumb-icon {
    height: auto;
    color: $breadcrumb-label-color;
    width: $size-font-xxsmall;
}
.flos-breadcrumb-icon:last-child {
    display: none;
}
.flos-breadcrumb-link {
    color: $breadcrumb-label-color;
    font-weight: 400;
    font-size: $size-font-xxsmall;
    line-height: $size-lineheight-small;
    &:hover,
    &:focus {
        color: $breadcrumb-label-color--focus;
    }
}
.flos-breadcrumb-link:nth-last-of-type(1) {
    cursor: default;
}

@media (max-width: $screen-sm) {
    .flos-breadcrumb {
        display: none;
    }
}
