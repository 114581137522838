.flos-tabs {
    padding-top: $spacing-5;

    .icon-box-row {
        margin-bottom: $spacing-7;
    }
}

.flos-tabs-list {
    list-style-type: none;
    display: flex;
    justify-content: flex-start;
    gap: $spacing-5;
    padding: $spacing-5 0 0;
    margin-bottom: -1px;
    border-bottom: 1px solid $color-supplement-grey-light;

    &::-webkit-scrollbar {
        height: $spacing-3;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $color-supplement-grey-base;
        border-radius: $border-radius-small;
    }

    &::-webkit-scrollbar-corner {
        background-color: yellow;
    }

    /* browser decide if x is ok to scroll and show bars on, y hidden */
    overflow: auto hidden;
    /* prevent scroll chaining on x scroll */
    overscroll-behavior-x: contain;
    /* scrolling should snap children on x */
    scroll-snap-type: x mandatory;

    @media (hover: none) {
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }
}

.flos-tabs-list-item {
    padding-bottom: $spacing-3;
    margin-bottom: 0;
    scroll-snap-align: start;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    border-bottom: 4px solid $color-supplement-white-base;

    button {
        padding-top: 0;
        padding-bottom: 0;
        border: none;
        border-radius: 0;
        background-color: transparent;
        color: $color-primary-1-medium;

        &:hover,
        &:focus {
            background-color: transparent;
            color: $color-primary-1-medium;
            border: none;
        }
    }

    &.active {
        border-color: $tab-active-bottom-border;
    }
}
