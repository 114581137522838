.flos-package {
    display: flex;
    gap: $spacing-7;

    @media (max-width: $screen-sm-max) {
        flex-direction: column;
    }

    .flos-package-info {
        flex: 30vw;
        margin-top: $spacing-5;

        @media (max-width: $screen-sm-max) {
            flex-basis: unset;
        }

        .flos-package-info-action {
            @media (max-width: $screen-sm-max) {
                min-height: auto;
            }

            min-height: 200px;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            align-items: start;

            .flos-field {
                width: 100%;
            }
        }

        .flos-package-info-legends {
            padding-top: $spacing-5;

            @media (max-width: $screen-sm-max) {
                display: none;
            }

            .List {
                padding: 0px;
            }

            .flos-package-label {
                padding: $spacing-3 0px;
                border-bottom: 1px solid $color-supplement-grey-base;

                p,
                h6 {
                    margin: 0;
                }

                p > a {
                    color: $color-primary-2-base;
                }
            }
        }
    }

    .flos-package-details {
        flex: 70vw;

        @media (max-width: $screen-sm-max) {
            flex-basis: 100vw;

            .flos-package-coverage-radio {
                display: none;
            }
        }

        .flos-carousel {
            align-items: flex-start;
        }

        .flos-radio {
            display: flex;
            justify-content: center;
            background-color: unset !important;
            border: unset;
            position: unset;
        }

        .flos-package-coverage {
            border: 1px solid transparent;
            margin-top: $spacing-5;
            cursor: pointer;

            &.active-package {
                border-color: $color-primary-2-base;
                background-color: $color-primary-2-light;
            }

            .flos-package-coverage-action {
                height: 200px;
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                text-align: center;
                align-items: center;
                padding-top: $spacing-7;

                .flos-radio > .dot {
                    position: relative;
                    top: 0;
                }

                .flos-package-coverage-helpblock {
                    position: absolute;
                    top: -$spacing-5;
                }
            }

            .flos-package-label {
                padding: $spacing-3 $spacing-5;
                @media (min-width: $screen-sm-max) {
                    justify-content: center;
                    .flos-package-label-mobile {
                        display: none;
                    }
                }
            }

            .flos-button {
                width: 100%;
            }
        }
    }

    .flos-package-price {
        .cvi-loading-dots {
            position: relative;
            top: 0;
        }
    }

    .flos-package-label {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 50px;
        justify-content: space-between;
    }
}
