.cvi-header {
    background-color: $site-header-bg;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 999;
    padding: 0 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    & > *:not(.backdrop) {
        z-index: 1;
        position: relative;
    }

    .logo {
        margin-top: 13px;
        flex-grow: 1;
    }

    .backdrop {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: transparent;
        transition: background-color 0.2s ease-out;
        z-index: 0;
    }

    .primary-action {
        padding: 22px;
        display: flex;
        align-items: center;
        a:not(.flos-button) {
            color: $color-secondary-2-base;
        }
    }
    .hidden-on-mobile {
        display: none;
    }
    .search {
        order: 1;
        a {
            margin-bottom: 0;
            display: none;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    li {
        display: block;
        transition-duration: 0.2s;
        margin-bottom: 0;
        flex-grow: 0;

        a:not(.flos-button) {
            display: block;
            padding: 15px;
            font-size: $size-font-base;
            color: $site-header-link-color;
            background-color: $site-header-bg;
            margin-bottom: 1px;
        }
        .flos-button {
            @media (max-width: 991px) {
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }
        &.hide-on-mobile {
            display: none;
        }
    }
    li a:hover:not(.flos-button),
    .menu-btn:hover {
        color: $site-header-link-color--hover;
        flos-icon {
            color: $site-header-link-color--hover;
        }
    }

    .menu {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex-wrap: nowrap;
        flex-basis: 100%;
        max-height: 0;
        transition: max-height 0.2s ease-out;
        overflow: hidden;
        flos-icon {
            display: none;
            position: relative;
            top: -1px;
            color: $site-header-link-color;
        }
        ul {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            flex-basis: 100%;
            min-width: 200px;
            & ~ a {
                background-color: $color-supplement-grey-light;
            }
        }
    }

    .submenu {
        &.submenu--two-col {
            @media (min-width: $screen-md) {
                display: grid !important;
                grid-template-columns: repeat(12, 1fr);
                min-width: max-content;
                left: 0;
                background-color: $color-supplement-white-base;

                li {
                    grid-column-end: span 6;
                    a {
                        border-bottom: none !important;
                    }
                }
            }
        }

        li {
            a {
                background-color: $color-supplement-white-base;
                color: $site-header-submenu-link-color;
                flos-icon {
                    display: inline-block;
                    color: $site-header-submenu-link-color;
                }
                &:hover {
                    color: $site-header-submenu-link-color;
                    flos-icon {
                        color: $site-header-submenu-link-color;
                    }
                    @media (max-width: 991px) {
                        font-weight: 700;
                    }
                }
            }
        }
    }

    .menu-icon {
        cursor: pointer;
        display: inline-block;
        float: right;
        padding: 38px 7px 38px 22px;
        position: relative;
        user-select: none;
        margin-bottom: 0;
    }

    .navicon {
        background: $site-header-link-color;
        display: block;
        height: 4px;
        position: relative;
        transition: background 0.2s ease-out, height 0.2s ease-out;
        width: 30px;

        &:before,
        &:after {
            background: $site-header-link-color;
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            transition: all 0.2s ease-out;
            width: 100%;
        }
        &:before {
            top: 8px;
        }
        &:after {
            top: -8px;
        }
    }

    .menu-btn {
        display: none;
        &:checked {
            & ~ .menu {
                max-height: 100vh;
            }
            & ~ .menu-icon {
                .navicon {
                    background: transparent;
                    height: 2px;
                    &:before {
                        transform: rotate(-45deg);
                    }
                    &:after {
                        transform: rotate(45deg);
                    }
                }
                &:not(.steps) .navicon:before,
                &:not(.steps) .navicon:after {
                    top: 0;
                }
            }
        }
        &:checked ~ .backdrop {
            background-color: $color-secondary-4-base;
        }
    }

    @media (min-width: 992px) {
        padding: 15px 32px 0;
        justify-content: center;
        flex-wrap: nowrap;
        .logo {
            margin-top: -3px;
            margin-right: 15px;
            flex-grow: 0;
        }
        .primary-action {
            display: none;
        }
        .cvi-header-spacer {
            text-align: right;
            padding-left: 20px;
            width: auto;
            flex-grow: 1;
        }
        .search {
            order: 0;
            a {
                display: block;
            }
        }
        .menu {
            max-height: none;
            flex-direction: row;
            justify-content: center;
            overflow: visible;
            flex-basis: auto;
            flos-icon {
                display: inline-block;
            }
        }
        .menu-icon {
            display: none;
        }

        > ul {
            li:last-child a:not(.flos-button) {
                padding-right: 0;
            }
        }

        li {
            margin-bottom: 0;
            position: relative;

            a:not(.flos-button) {
                margin-bottom: 0;
                padding: 15px 10px 25px;
                white-space: nowrap;
                display: inline-block;
            }

            &.hidden-on-mobile {
                display: block;
            }
            ul {
                visibility: hidden;
                opacity: 0;
                position: absolute;
                transition: all 0.5s ease;
                right: 0;
                display: none;
                box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
                width: 100%;
            }
        }
        ul li:hover > ul,
        ul li:focus-within > ul,
        ul li ul:hover,
        ul li ul:focus {
            visibility: visible;
            opacity: 1;
            display: block;
            form {
                pointer-events: auto;
                opacity: 1;
                display: flex;
                width: 400px;
            }
        }
        ul li ul li {
            clear: both;
            width: 100%;
            a:not(.flos-button) {
                border-bottom: $site-header-submenu-link-border-bottom;
                padding-bottom: 15px;
                display: block;
            }
            &:hover a {
                background-color: $color-secondary-4-base;
                border-bottom: $site-header-submenu-link-border-bottom--hover;
            }
        }
    }

    @media (min-width: 1201px) {
        .logo {
            margin-top: -1px;
        }
        li {
            a:not(.flos-button) {
                font-size: $size-font-base;
                padding: 15px 10px 25px;
            }
            ul a {
                font-size: $size-font-base;
            }
        }
        .cvi-header-spacer {
            padding-left: 100px;
        }
    }

    @media (min-width: 1360px) {
        li {
            a:not(.flos-button) {
                padding: 15px 20px 25px;
            }
        }
        .cvi-header-spacer {
            padding-left: 130px;
        }
    }
}

.search-form {
    padding: 15px;
    display: flex;
    background-color: $color-supplement-white-base;

    .form-control {
        min-width: 0; // flexbox fix.
        font-size: $size-font-base; // Prevent iOS zoom behavior.
        background-color: $color-supplement-grey-light;
        &::placeholder {
            color: $color-secondary-2-base;
            opacity: 1;
        }
        &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $color-secondary-2-base;
        }
    }

    .form-group {
        margin-bottom: 0;
    }

    @media (max-width: $screen-sm-max) {
        // Override any [style] attr properties from desktop view.
        width: 100% !important;
        margin-left: 0 !important;
        margin-bottom: 10px;
        .btn {
            padding: 8px 10px;
        }
        .form-group {
            width: 100%;
        }
    }

    @media (min-width: $screen-md) {
        opacity: 0;
        pointer-events: none;
        width: 0;
        position: absolute;
        right: 0;
        white-space: nowrap;
        display: none;
        z-index: 1000;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        border-bottom: $site-header-search-form-bottom-border;
        padding-top: $site-header-search-form-padding-top;

        .has-sticky-nav & {
            bottom: -60px;
        }

        .is-searching & {
            pointer-events: auto;
            opacity: 1;
            display: flex;
            width: 400px;
        }

        .no-flexbox & {
            max-width: 300px;

            .form-control {
                width: 70%;
                float: left;
            }
            .btn {
                width: 20%;
                float: right;
            }
        }
    }

    .btn {
        height: 40px;
        line-height: 20px;
        margin: 0 0 0 15px;
        position: relative;
    }
}
