.flos-splash {
    display: block;
    height: 184px;
    border-radius: $border-radius-medium;
    box-shadow: $shadow-large;

    @media (min-width: $screen-sm) {
        width: 310px;
        height: 184px;
    }

    &:not(.has-image) {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: $color-secondary-3-base;
        padding: $spacing-7;
        color: $color-supplement-white-base;
        text-align: center;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: inherit;
        }

        h3 {
            margin-bottom: $spacing-3;
        }

        p {
            margin-bottom: 0;
            max-width: 100%;
        }
    }

    &.has-image {
        position: relative;
        background-color: transparent;
        padding: 0;
        width: 310px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: $border-radius-medium;
        }
    }
}
