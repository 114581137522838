.flos-local-nav-sticky {
    position: sticky;
    top: $site-header-desktop-height;
    z-index: 997;
    @media (max-width: $screen-sm-max) {
        top: 64px;
    }
}
.flos-local-nav {
    &.Section {
        padding-top: $spacing-3;
        padding-bottom: $spacing-3;
        border-bottom: 1px solid transparent;
        @media (min-width: $screen-md) {
            padding-bottom: 0;
        }
    }
}

.flos-local-nav-sticky ~ .Section[id],
div > .Section[id] {
    scroll-margin-top: 130px;
}

.flos-local-nav-container {
    &-mobile {
        .flos-field {
            margin-bottom: 0;
        }
        .flos-select {
            width: 100%;
            background-color: transparent;
            color: $color-secondary-2-base;
            padding: $spacing-4 $spacing-10 $spacing-4 $spacing-7;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;

            option {
                background-color: $nordea-grey-cool;
            }
        }

        @media (min-width: $screen-md) {
            display: none;
        }
    }

    &-desktop {
        display: flex;
        justify-content: center;
        gap: $spacing-5;
        flex-wrap: nowrap;

        @media (max-width: $screen-sm-max) {
            display: none;
        }

        .flos-button ~ .flos-button {
            margin-top: 0;
            margin-left: 0;
        }
        .flos-button {
            padding: 11px clamp(12px, 1vw, 32px);
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
            border-radius: 0;
        }
        .flos-button--outline {
            border: 4px solid transparent;
            background-color: transparent;
            color: $flos-local-nav-text-color;
            font-size: 14px;
            line-height: 16px;
            font-weight: 700;

            &:hover,
            &.hover,
            &:focus,
            &.focus {
                border-color: transparent;
                color: $flos-local-nav-text-color--hover;
                background-color: transparent;
            }
            &:active,
            &.active {
                border-bottom-color: $flos-local-nav-border-bottom-color--active;
                background-color: transparent;
            }
        }
    }
}
