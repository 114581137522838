@import 'src/sass/nlp-tokens';
@import 'src/sass/settings/variables-shared';
@import 'src/sass/settings/variables-topdanmark';
@import 'base/fonts';
// @import 'src/sass/settings/bootstrap';
@import 'settings/mixins';

@import 'base/normalize';
@import 'base/reset';
@import 'base/grid';
@import 'base/typography';
@import 'base/icons';
@import 'base/links-topdanmark';
@import 'base/forms';
@import 'base/form-fields-topdanmark';
@import 'base/boxes';
@import 'base/boxes-extra';
@import 'base/misc';
@import 'base/shadow';
@import 'base/responsive-utilities';
@import 'base/utilities';
@import 'base/utilities-extra';
@import 'base/component-animations';

@import 'components/car-banner';
@import 'components/coverage-inline-list';
@import 'components/coverage-item';
@import 'components/panels';
@import 'settings/bootstrap/popovers';
@import 'components/popover';
@import 'components/profile';

@import '../cms/site-footer/site-footer--top';
@import '../cms/site-header/site-header--top';
@import '../core/button/buttons';
@import '../core/button/button--top';
@import '../core/button/buttons-extra';
@import '../core/forms/address-field/address-field-react';
@import '../core/forms/address-field/address-field-html/address-field-html';
@import '../core/forms/checkbox/checkbox-topdanmark';
@import '../core/forms/checkbox/deprecated/checkbox_deprecated';
@import '../core/forms/checkbox/deprecated/checkbox-box_deprecated';
@import '../core/forms/checkbox/deprecated/checkbox-group';
@import '../core/forms/date-field/datepicker-deprecated';
@import '../core/forms/date-field/date-field-topdanmark';
@import '../core/forms/radio/flos-radio-topdanmark';
@import '../core/forms/radio/radio_deprecated';
@import '../core/forms/range-input/range-slider-topdanmark';
@import '../core/forms/text-field/textfield';
@import '../core/forms/time-field/timefield';
@import '../core/forms/textarea/textarea';
@import '../core/forms/toggle/flos-toggle--topdanmark';
@import '../core/forms/tooltip/infodialog';
@import '../core/forms/tooltip-icon/tooltip-icon';
@import '../core/forms/tooltip-labeled/tooltip-labeled-topdanmark';
@import '../core/layout/section/section-extra.scss';
@import '../core/layout/section/section-topdanmark.scss';
@import '../core/table/table';
@import '../core/table/legacy/table-receipt';
@import '../core/typography/list/list';
@import '../core/forms/select/typeahead/typeahead-field-topdanmark.scss';
@import '../components/navigation/dropdown/flos-dropdown--topdanmark';

@import '../components/accordion/deprecated/accordion';
@import '../components/accordion/flos-accordion--topdanmark';
@import '../components/attention-elements/attention-banner/attention-banner';
@import '../components/attention-elements/attention-box/attention-box';
@import '../components/attention-elements/badge/badge--topdanmark';
@import '../components/attention-elements/counter/counter';
@import '../components/attention-elements/splash/splash-topdanmark';
@import '../components/attention-elements/loading-dots/cvi-loading-dots';
@import '../components/attention-elements/loading-spinner/loading-spinner';
@import '../components/attention-elements/message-alt/message';
@import '../components/attention-elements/message/message';
@import '../components/attention-elements/modal/modal';
@import '../components/attention-elements/pricebar/pricebar';
@import '../components/attention-elements/pricebar/summary-bar';
@import '../cms/hero/hero';
@import '../components/banners/flos-hero/flos-hero';
@import '../cms/hero/hero-extra';
@import '../components/banners/flos-image-banner/flos-image-banner';
@import '../components/banners/page-header/page-header';
@import '../components/banners/page-header/flos-pageheader--topdanmark';
@import '../components/banners/streamer/streamer';
@import '../cms/box-callout/callout';
@import '../components/boxes/deprecated/box-promo/box-promo';
@import '../components/boxes/deprecated/box-ksp/box-ksp';
@import '../cms/box-usp/box-usp';
@import '../components/boxes/deprecated/consent-info-box/consent-info-box';
@import '../components/boxes/deprecated/coverage-box/deductibles';
@import '../components/boxes/deprecated/coverage-box/power-of-attorney-item';
@import '../components/boxes/deprecated/coverage-box/product-box';
@import '../components/boxes/deprecated/coverage-box/responsive-coverage-item';
@import '../cms/flos-consent-iframe/flos-consent-iframe';
@import '../components/boxes/icon-box/icon-box';
@import '../components/boxes/info-box/info-box';
@import '../components/boxes/deprecated/list-box/list-box';
@import '../components/boxes/list-box/flos-list-box-topdanmark';
@import '../components/boxes/deprecated/box-list/box-list';
@import '../components/boxes/selectable-box/selectable-box--topdanmark';
@import '../cms/decision-tree/box-selectable';
@import '../components/boxes/deprecated/well/well';
@import '../components/boxes/link-box/link-box--topdanmark';
@import '../components/boxes/box/flos-box';
@import '../components/flow-menu/deprecated/flow-menu';
@import '../components/flow-menu/flos-flow-menu--topdanmark';
@import '../components/navigation/breadcrumb/breadcrumb--top';
@import '../components/navigation/local-nav/flos-local-nav--topdanmark';
@import '../components/banners/card/card';
@import '../components/navigation/pagination/pagination-topdanmark';
@import '../components/navigation/processbar/processbar';
@import '../components/navigation/processbar/step-nav';
@import '../components/navigation/shortcut-bar/shortcut-bar';
@import '../cms/nav-list/nav-list';
@import '../cms/article-spot/article-spot';
@import '../components/navigation/tabs/tabs-topdanmark';
@import '../components/navigation/flos-link/flos-link--top';
@import '../components/navigation/carousel/carousel';
@import '../collections/package-selector/package-selector.scss';
@import '../components/navigation/progressbar/flos-progressbar.scss';
@import '../components/wizard/flos-wizard.scss';
@import '../components/attention-elements/pill/pill.scss';

#log-ind-modal__bodyContent h3 {
    color: white;
}
