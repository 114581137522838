.flos-dropdown {
    &-outer {
        position: relative;
    }

    &-inner {
        position: absolute;
        border: 1px solid $flos-dropdown-inner-border-color;
        background: $color-supplement-white-base;
        border-radius: $border-radius-medium;
        padding: $spacing-5;
        max-width: 300px;
        z-index: 1;
        box-shadow: $shadow-large;

        &--show {
            animation: dropdown-slide-in 0.15s ease-in;
            .flos-dropdown-list {
                animation: dropdown-slide-in 0.18s ease-in;
            }
        }
    }
    &-list {
        position: relative;
        list-style-type: none;
        max-height: 300px;
        overflow-y: auto;
        margin: 0;
        padding: 0;
        width: max-content;
        min-width: 200px;

        &-item {
            border-radius: $border-radius-small;
            padding: $spacing-3;
            margin-bottom: 0;
            cursor: pointer;
            outline: none;
            font-size: $size-font-xsmall;
            font-weight: 400;
            line-height: $size-lineheight-medium;
            text-align: left;
            font-family: $font-family-base;
            letter-spacing: normal;
            text-transform: none;
            width: 100%;

            &:hover {
                background-color: $color-secondary-2-light;
            }

            &:active,
            &:focus,
            &[aria-selected='true'] {
                color: $flos-dropdown-list-text-color--selected;
            }
        }
    }

    &.flos-dropdown--disabled {
        p {
            color: $color-supplement-grey-medium;
        }
    }

    .flos-dropdown-select {
        .flos-button--secondary:not([disabled]) {
            color: $flos-dropdown-button-text-color;
            border: 1px solid $flos-dropdown-button-border-color;
            background-color: $flos-dropdown-button-background-color;

            &:hover,
            &:focus {
                background-color: $flos-dropdown-button-background-color--hover;
                border-color: $flos-dropdown-button-border-color--hover;
            }
            outline: none;
            flos-icon {
                color: $color-primary-2-base;
            }
        }
    }

    // Sizes
    &.flos-dropdown--small {
        .flos-button--secondary {
            padding: $spacing-3;
            width: unset;
        }
    }
}

@keyframes dropdown-slide-in {
    0% {
        opacity: 0;
        max-height: 0;
        width: 0;
    }

    25% {
        opacity: 0.25;
        max-height: 75px;
        width: 75px;
    }

    50% {
        opacity: 0.5;
        max-height: 150px;
        width: 150px;
    }

    75% {
        opacity: 0.75;
        max-height: 225px;
        width: 225px;
    }

    100% {
        opacity: 1;
    }
}
