@use 'sass:math';

.List,
ul,
ol {
    padding: 0 0 0 20px;

    li {
        font-size: $size-font-base;
        line-height: $size-lineheight-base;
        font-weight: 400;
        position: relative;
        margin-bottom: $spacing-3;

        &:last-child {
            margin: 0;
        }
    }
    margin-top: 0;
    margin-bottom: $spacing-5;

    ul,
    ol {
        margin-bottom: 0;
    }
}

ol.List--numbered-circle {
    counter-reset: numList;
    list-style: none;
    padding: 0;

    li {
        display: flex;
        gap: $spacing-5;
        margin: 0 0 $spacing-7;
        align-items: center;
        p:last-child {
            margin-bottom: 0;
        }
    }

    li:before {
        counter-increment: numList;
        content: counter(numList);
        flex-shrink: 0;
        flex-grow: 0;
        text-align: center;
        width: $spacing-11;
        height: $spacing-11;
        background: $color-secondary-3-base;
        color: $color-supplement-white-base;
        border-radius: 50%;
        line-height: $spacing-11;
        font-size: $size-font-medium;
    }
}

.List {
    a:hover {
        text-decoration: underline;
    }
}

.List--icons {
    list-style-type: none;
    padding-left: 0;

    li {
        display: flex;
        align-items: center;
        gap: $spacing-5;
    }

    flos-icon {
        flex-shrink: 0;
        margin: 0;
    }
}

dl {
    margin-top: 0;
    margin-bottom: $line-height-computed;
}

dt,
dd {
    line-height: $line-height-base;
}

dt {
    font-weight: 400;
}

dd {
    margin-left: 0; // Undo browser default
    font-size: 1.8rem;
}

.dl-stacked {
    margin: 10px 0;

    dt {
        margin: 0 0 10px;
        line-height: $headings-line-height;
        color: $headings-color;
        margin-top: math.div($line-height-computed, 2);
        margin-bottom: 5px;
        font-weight: bold;
    }
    dd {
        margin-bottom: 25px;
    }
}

// Horizontal description lists
//
// Defaults to being stacked without any of the below styles applied, until the
// grid breakpoint is reached (default of ~768px).
.dl-horizontal {
    margin: 10px 0;
    h4 {
        font-size: 18px;
        margin: 36px 0 20px !important;
    }
    dd {
        @include clearfix; // Clear the floated `dt` if an empty `dd` is present
    }

    dd {
        margin-bottom: 15px;
    }

    @media (min-width: $screen-xs) {
        dt,
        dd {
            margin-bottom: 20px;
        }

        dt {
            width: 215px;
            float: left;
            clear: left;
            padding-right: 15px;
            //@include text-overflow;
        }
        dd {
            margin-left: 215px;
        }
    }

    dt:last-of-type,
    :last-child {
        margin-bottom: 0;
    }
}

.tds-LinkList {
    list-style-type: none;
    padding-left: 0;
}

.tds-LinkList-link {
    display: block;
    position: relative;
    padding: 13px 40px 13px 20px;
    color: $color-secondary-2-base;
    background: $color-supplement-white-base;
    transition: all 0.2s;
    max-width: 700px;
    border-bottom: 2px solid $color-primary-1-base;

    &:hover,
    &:focus {
        text-decoration: none;
        background: $color-secondary-4-base;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        right: 20px;
        background: $color-primary-1-base;
        transform: rotate(45deg);
    }

    &::before {
        width: 10px;
        height: 2px;
        top: 20px; // calc fallback.
        top: calc(50% - 4px);
    }

    &::after {
        width: 2px;
        height: 10px;
        top: 22px; // calc fallback.
        top: calc(50% - 2px);
        right: 24px;
        transition: transform 0.3s;
    }

    &:active {
        color: $color-supplement-white-base;
        background: $color-primary-1-base;

        &::before,
        &::after {
            background: $color-supplement-white-base;
        }
    }
}

.list-table {
    .dl-horizontal {
        dt {
            text-align: left;
            white-space: normal;
            width: 250px;
        }
    }

    .Section-header {
        font-size: 19px;
    }

    .spacer--with-border {
        border-bottom: 1px solid #ccc;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
