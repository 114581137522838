.site-header-segments {
    position: relative;
    z-index: 999;
    padding: $spacing-3 0;
    border-bottom: 1px solid $color-primary-1-base;
    background-color: $color-primary-1-base;

    a {
        color: $color-supplement-white-base;
        &:hover {
            color: $nordea-blue-medium;
        }
    }

    .site-header-menu-item a:not(.flos-button) {
        color: $color-supplement-white-base;
        font-weight: 500;
        &:hover {
            color: $nordea-blue-medium;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        gap: $spacing-7;
        justify-content: space-evenly;
    }

    li {
        margin: 0;
        line-height: $size-lineheight-small;

        &.active a:not(.flos-button) {
            color: $nordea-blue-medium;
        }
    }
    @media (min-width: $screen-md) {
        ul {
            justify-content: center;
        }
    }
}
