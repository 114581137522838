.Hero--alt {
    color: $color-primary-1-base;
    background-color: #fff;
    min-height: 350px;
    overflow: visible;

    .Hero-box {
        margin: 0;
        max-width: 580px;
        background-color: #fff;
        color: $color-primary-1-dark;
        bottom: -50px;
        @media (max-width: $screen-xs-max) {
            width: 100%;
            bottom: 0;
            .Hero-box-content {
                width: 100%;
            }
        }
    }

    .Hero-box-header {
        color: $color-primary-1-base;
    }

    .lead {
        p {
            color: $color-primary-1-base;
        }
    }

    .Media--image {
        margin-top: $spacing-3;
        padding-top: $spacing-3;
        height: 100%;
        width: auto;

        @media (max-width: $screen-md) {
            width: 100%;
        }
        @media (max-width: $screen-xs-max) {
            display: none;
        }
    }

    .Media--image-mobile {
        display: none;
        width: 100%;
        height: auto;
        margin-top: -30px;

        @media (max-width: $screen-xs-max) {
            display: block;
        }
    }

    .container {
        @media (max-width: $screen-xs-max) {
            width: 100%;
        }

        .Hero-box {
            padding-top: 20px;
        }
    }
}

.Hero--alt.Hero--pos-right {
    .Hero-bg-fit {
        @media (max-width: $screen-xs-max) {
            display: none;
        }
    }

    .Media--image-mobile {
        display: none;
        width: 100%;
        height: auto;
        margin-top: -30px;

        @media (max-width: $screen-xs-max) {
            display: block;
        }
    }

    .container {
        margin-bottom: 0;
        position: relative;
    }

    .Hero-box {
        .Hero-box-content {
            color: $color-primary-1-base;
        }
    }

    .container {
        @media (max-width: $screen-xs-max) {
            width: 100%;
        }
    }
}

.Hero--alt-color-styling {
    background-color: $color-secondary-4-base;
    color: $color-primary-1-base;
}

.Hero--image {
    border-bottom: 70px solid $color-supplement-grey-light;
    overflow: visible;
    min-height: 350px;

    &.Hero-slim {
        border-bottom: none;
        overflow: visible;
        min-height: 208px;
        @media (max-width: $screen-md) {
            min-height: 156px;
            border-bottom: 0;
        }
    }

    &.Hero--bg-default {
        background-color: $color-secondary-4-base;
    }

    .Hero-box-header,
    .label,
    .lead,
    .Hero-box-content {
        color: $color-primary-1-base;
    }

    .container {
        position: relative;
    }

    .Hero-box {
        padding-top: 0;
        margin-top: 50px;
        margin-bottom: 0;
        min-width: initial;
        background: none;
        @media (max-width: $screen-md) {
            padding-bottom: 0;

            &.Hero-slim {
                margin-top: 0px;
            }
        }
    }

    .Hero-image {
        margin: 0;
        color: $color-primary-1-base;
        position: absolute;
        bottom: -50px;
        right: 0;
        overflow: hidden;
        height: 100%;
        @media (max-width: $screen-md) {
            position: relative;
            height: 350px;
        }
    }

    .Hero-box-content {
        @media (max-width: $screen-md) {
            margin-bottom: 0;
        }
    }

    .Media--image {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &.Hero--pos-right {
        .Hero-image {
            left: 0;
        }
    }
    .btn-outline:hover {
        background-color: $color-supplement-grey-light;
    }
}
.Hero-image {
    z-index: 2;
}
