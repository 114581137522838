.icon-box-wrapper {
    &--title-outside {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $spacing-3;

        .icon-box-wrapper-title {
            margin-bottom: 0;
            text-align: center;
            font-size: 14px;
            line-height: 16px;
            font-weight: 700;
            color: $nordea-blue-deep;
        }
    }
}
.icon-box {
    color: $color-primary-1-dark;
    display: flex;
    gap: $spacing-5;
    align-items: center;
    justify-content: flex-start;
    background-color: $color-supplement-white-base;
    padding: calc(#{$spacing-5} - 1px);
    border: 1px solid $color-supplement-grey-light;
    border-radius: $border-radius-small;
    height: fit-content;
    position: relative;
    transition: border-color 0.3s ease-in-out;

    &:hover,
    &:focus {
        background-color: $color-secondary-2-light;
    }

    &:focus {
        border-color: $color-secondary-2-base;
    }

    flos-icon {
        color: $color-secondary-2-base;
        flex-shrink: 0;
    }

    @media (min-width: $screen-md) {
        flex-direction: column;
        box-shadow: $shadow-small;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            display: block;
            z-index: -1;
            width: 100%;
            height: 100%;
            border-radius: $border-radius-medium;
            box-shadow: $shadow-large;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
        }
    }

    .flos-pill {
        background-color: transparent;
        display: none;
        order: 0;
        @media (min-width: $screen-md) {
            justify-self: flex-end;
            order: 3;
        }
    }
}

.icon-box-icon {
    color: $color-primary-1-dark;
    @media (min-width: $screen-md) {
        width: 64px !important;
        height: 64px !important;
    }
}

.icon-box-title {
    margin-bottom: 0;
    @media (min-width: $screen-md) {
        text-align: center;
        height: $spacing-9;
    }
}

.icon-box--selected {
    border-color: $color-primary-2-base;
    background-color: $color-secondary-2-light;

    &:hover,
    &:focus {
        background-color: $color-secondary-2-light;
    }

    @media (min-width: $screen-md) {
        box-shadow: $shadow-large;
        &:after {
            opacity: 1;
        }
    }
}

.icon-box-header {
    display: flex;
    align-items: center;
    gap: $spacing-5;
    order: 3;
    margin-left: auto;
    height: $spacing-7;
    @media (min-width: $screen-md) {
        margin-bottom: $spacing-2;
        margin-left: 0;
        width: 100%;
        order: 0;
        .flos-pill {
            display: inline-block;
            &:only-child {
                margin-left: auto;
            }
        }
    }
}

.icon-box-selected-icon {
    margin-right: auto;
    circle {
        color: $color-primary-2-base;
    }
    &.has-fill {
        circle {
            color: $color-primary-2-base;
        }
    }
    order: 3;
    @media (min-width: $screen-md) {
        order: 0;
    }
}

.icon-box-title-wrap {
    .icon-box-title {
        margin-bottom: $spacing-3;
        @media (min-width: $screen-md) {
            margin-bottom: 0;
        }
    }
    .flos-pill {
        display: inline-block;
        @media (min-width: $screen-md) {
            display: none;
        }
    }
}

.icon-box-content {
    width: 100%;
    order: 3;
    //  flex: 0 0 100%;

    & > *:last-child {
        margin-bottom: 0;
    }
}

.Box--icon {
    text-align: center;
    padding-top: 34px;
    margin: $spacing-5 0;

    .Box-header {
        @extend .h6;
    }

    &:hover,
    &:focus {
        background-color: $color-secondary-4-base;

        .Box-header {
            color: $color-secondary-2-base;
        }

        flos-icon {
            color: $color-secondary-2-base;
        }
    }

    flos-icon {
        padding-bottom: 25px;
        margin-right: 0;
        color: $color-primary-1-dark;
    }
}

// variants
.icon-box--light {
    background-color: transparent;
    box-shadow: none;
    border: transparent;
    &:hover,
    &:focus {
        background-color: transparent;
    }
    &:focus {
        border-color: transparent;
    }
    .icon-box-icon {
        @media (min-width: $screen-md) {
            width: 77px !important;
            height: 77px !important;
        }
    }
}

.icon-box--compact {
    border-radius: $border-radius-medium;

    @media (min-width: $screen-md) {
        gap: 8px;
        border: none;
        width: 86px;
    }

    .icon-box-icon {
        @media (min-width: $screen-md) {
            width: 32px !important;
            height: 32px !important;
        }
    }

    .icon-box-title {
        margin-bottom: 0;
        text-align: center;
        @media (min-width: $screen-md) {
            height: auto;
            font-size: $size-font-xxsmall;
            line-height: $size-lineheight-small;
            font-weight: 400;
        }
    }
}

.icon-box--title-outside {
    flex-direction: column;
    box-shadow: none;
    background-color: $color-supplement-white-base;
    border-radius: 50%;

    &:hover,
    &:focus {
        background-color: $nordea-blue-light;
        outline: none !important;
    }

    &:focus {
        border-color: $nordea-blue-light;
    }

    flos-icon {
        width: 32px;
        height: 32px;
    }

    .icon-box-icon {
        @media (min-width: $screen-md) {
            width: 32px !important;
            height: 32px !important;
        }
    }

    &.icon-box--selected {
        border-color: $nordea-blue-medium;
        background-color: $nordea-blue-medium;
        box-shadow: none;

        &:hover,
        &:focus {
            background-color: $nordea-blue-medium;
        }

        @media (min-width: $screen-md) {
            &:after {
                opacity: 1;
            }
        }
    }

    @media (min-width: $screen-md) {
        flex-direction: column;
        &:after {
            box-shadow: none;
        }
    }
}

// Wrappers - row or grid-list
.icon-box-row {
    display: flex;
    gap: $spacing-7;
    list-style: none;
    padding: 0;
    margin: 0;
    flex-wrap: nowrap;

    @media (max-width: $screen-sm-max) {
        gap: $spacing-5;
    }

    &::-webkit-scrollbar {
        height: $spacing-3;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $color-supplement-grey-base;
        border-radius: $border-radius-small;
    }

    /* browser decide if x is ok to scroll and show bars on, y hidden */
    overflow: auto hidden;
    /* prevent scroll chaining on x scroll */
    overscroll-behavior-x: contain;
    /* scrolling should snap children on x */
    scroll-snap-type: x mandatory;

    @media (any-hover: none) {
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
        padding-left: $spacing-5;
        padding-right: $spacing-5;
    }

    li {
        margin: 0 0 $spacing-3 0;
        flex-grow: 1;
        flex-basis: 0;
        max-width: 72px;
        scroll-snap-align: end;
        white-space: normal;

        @media (max-width: $screen-sm-max) {
            max-width: $spacing-11;
        }

        &:first-child {
            margin-left: auto;
        }
        &:last-child {
            margin-right: auto;
            scroll-snap-align: start;
        }
    }
}

.icon-box-grid-list {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        margin: 0;
        display: flex;
        flex-direction: column;
    }

    @media (max-width: $screen-sm-max) {
        .icon-box {
            position: relative;
            z-index: 0;
            border-radius: 0;
            margin-bottom: -1px;

            &.icon-box--selected {
                z-index: 1;
            }
        }

        li {
            position: relative;
            &:first-child {
                .icon-box {
                    border-top-left-radius: $border-radius-small;
                    border-top-right-radius: $border-radius-small;
                }
            }

            &:last-child {
                .icon-box {
                    border-bottom-left-radius: $border-radius-small;
                    border-bottom-right-radius: $border-radius-small;
                }
            }
        }
    }
    @media (min-width: $screen-md) {
        display: grid;
        gap: $spacing-7;
        grid-template-columns: repeat(4, 1fr);
    }
}
