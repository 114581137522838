.u-ButtonStack {
    display: inline-flex;
    flex-direction: column;
    .btn:not(:last-child) {
        margin-bottom: 20px;
    }
}

.u-Space {
    margin: 15px 0;

    @media (min-width: $screen-sm) {
        margin: 2vh 0;
    }

    &--lg {
        margin: 35px 0;

        @media (min-width: $screen-sm) {
            margin: 5vh 0;
        }
    }
}

.u-Space-up {
    margin-top: 2vh;

    &--lg {
        margin-top: 5vh;
    }

    &--sm {
        margin-top: 1vh;
    }
}

.u-Space-down {
    margin-bottom: 2vh;

    &--lg {
        margin-bottom: 5vh;
    }
}

.u-Center {
    text-align: center;
    p {
        margin-left: auto;
        margin-right: auto;
    }
}

.u-Vtop {
    vertical-align: top !important;
}

.u-Vbottom {
    vertical-align: bottom !important;
}

.u-Vcenter {
    vertical-align: middle !important;
}

.u-Nowrap {
    white-space: nowrap;
}

.u-Normalwrap {
    white-space: normal;
}

.margin-top-align {
    margin-top: -18px;
}

.u-borderTop {
    &--grey {
        border-top: 1px solid $nordea-grey-medium;
    }
}

.u-text-color-dim {
    color: $color-supplement-grey-dark;
}

.u-padding-top {
    padding-top: 75px !important;
}

.u-no-horz-padding {
    padding-left: 0;
    padding-right: 0;
}

@media (max-width: 480px) {
    .u-margin-left {
        margin-left: 67px !important;
    }
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.visible-grid-float-up {
    @media (max-width: $screen-sm-max) {
        display: none !important;
    }
}

.visible-grid-float-down {
    @media (min-width: $screen-md) {
        display: none !important;
    }
}

.collapsed-col {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.clearfix {
    &:before,
    &:after {
        content: ' ';
        display: table;
        clear: both;
    }
    *zoom: 1;
}

.clear-fix {
    content: ' ';
    display: table;
    clear: both;
}

.u-indent-has-icon-before {
    margin-left: 32px;
    @media (max-width: $screen-md) {
        margin-left: 0;
        text-align: center;
    }
}

.u-No-bottom-margin {
    margin-bottom: 0;
}

.u-no-top-margin {
    margin-top: 0;
}

.hide {
    display: none !important;
}

//! use in output-content
.hidden {
    display: none !important;
}

//! use in Affix plugin
.affix {
    position: fixed;
}

//! use in box-media
.embed-responsive {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;

    .embed-responsive-item,
    iframe,
    embed,
    object,
    video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
    // 16:9
    &-16by9 {
        padding-bottom: 56.25%;
    }
    // 4:3
    &-4by3 {
        padding-bottom: 75%;
    }
}

//! use in legacy table
.media-right,
.media > .pull-right {
    padding-left: 10px;
}

//! use in receipt table
.pull-right {
    float: right !important;
}

//! Contextual colors, CMS eforms-field-render
.text-muted {
    color: #777;
}

//! CMS partnersite
.text-center {
    text-align: center;
}

//! use to show modal
.show {
    display: block !important;
}
