.timefield {
    &-wrapper {
        position: relative;
    }
    &-dialog {
        position: absolute;
        z-index: 5;
        width: 100%;
        background: white;
        padding: $spacing-5;
        border: 1px solid $formfield-input-bordercolor--active;
        border-top: none;
        border-radius: $formfield-input-border-radius;
        max-width: 477px;
        left: 0;

        &.visibilityHidden {
            visibility: hidden;
        }
        &-above {
            border-top-right-radius: $border-radius-small;
            border-top-left-radius: $border-radius-small;
            border-top: 1px solid $color-secondary-2-light;
            border-bottom: none;
        }

        @media (max-width: 350px) {
            padding: $spacing-3;
        }

        &-inner {
            max-height: 144px;
            overflow-y: scroll;
            display: grid;
            grid-template-columns: repeat(4, auto);
            gap: $spacing-5;
            padding-right: $spacing-5;

            @media (max-width: 350px) {
                gap: $spacing-3;
            }

            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-track {
                background-color: $color-secondary-4-base;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $color-primary-1-base;
            }

            .flos-button {
                margin: 0;
                min-width: 0;
                padding: $spacing-5 $spacing-2;

                @media (max-width: 350px) {
                    font-size: $size-font-xxsmall;
                }

                &.flos-button--no-fill {
                    background: $color-supplement-grey-base;
                    border: none;

                    &:hover {
                        background: #eaf1f9 radial-gradient(circle, transparent 1%, #eaf1f9 1%) center/15000%;
                    }
                    &:focus {
                        outline: none;
                        background-color: $color-secondary-2-light;
                        border: 1px solid $color-primary-2-medium;
                        color: $color-primary-1-base;
                    }
                    &:disabled {
                        background: white;
                        color: $color-supplement-grey-medium;
                        cursor: not-allowed;
                    }
                    &.active {
                        background: $color-secondary-2-base;
                        color: white;
                    }
                }
            }
        }
    }
}
